import { setLocale } from 'yup';
import translations from '../translations/main.json';

setLocale({
  mixed: {
    required: translations?.['cc4.form.validation.general.required'],
    date: translations?.['cc4.form.validation.general.invalid-email'],
    notType: function notType(_ref) {
      switch (_ref.type) {
        case 'date':
          return translations?.['cc4.form.validation.general.invalid-date'];
        case 'number':
          return 'Not a number';
        case 'string':
          return 'Not a string';
        default:
          return 'Wrong type';
      }
    },
  },
  string: {
    email: translations?.['cc4.form.validation.general.invalid-email'],
    min: translations?.['cc4.form.validation.general.to-short'],
    max: translations?.['cc4.form.validation.general.to-long'],
  },
  date: {
    mixed: translations?.['cc4.form.validation.general.invalid-email'],
  },
});
