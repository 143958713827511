import React, { useEffect, useState } from 'react';
import { Redirect } from '@reach/router';
import { steps, translateRoute } from '../utils/routes';
import {
  CALCULATOR_TYPE,
  getLocalStorageState,
} from '../services/appLocalStorage';

const resolveRedirect = (stepName: string) => {
  const claimId = localStorage.getItem('yo:claimId');

  const shouldAuthenticate = getLocalStorageState('shouldAuthenticate');
  const calculatorType = getLocalStorageState(CALCULATOR_TYPE);

  switch (stepName) {
    case steps.CheckCompensation: {
      if (!calculatorType) {
        return '/';
      }
      // if (!hasFlightsData(data)) {
      //   return '/500';
      // }
      break;
    }
    case steps.Permission:
    case steps.CongratulationsBasepath: {
      if (typeof shouldAuthenticate !== 'boolean') {
        return '/';
      }
      // if (!hasFlightsData(data)) {
      //   return '/500';
      // }
      break;
    }
    case steps.ClaimAmount:
    case steps.BookingReference:
    case steps.Questionnaire:
    case steps.UploadDocuments:
    case steps.PassengersList: {
      if (!claimId) {
        return '/';
      }
      break;
    }
  }
};

interface StepObserverProps {
  stepName: string;
  customerFlights?: [{}];
}

const StepObserver = ({ stepName }: StepObserverProps) => {
  const [redirect, setRedirect] = useState<'/' | '/500'>();

  useEffect(() => {
    setRedirect(resolveRedirect(stepName));
  }, [stepName]);

  if (redirect) {
    const to = Array.isArray(redirect) ? translateRoute(redirect) : redirect;
    return <Redirect noThrow={true} to={to} />;
  }

  return null;
};

export default StepObserver;
