import {
  getLocalStorageState,
  PRODUCT_TYPE,
} from '../services/appLocalStorage';
import config from '../config.json';

function useDataLayer() {
  const dataLayer =
    typeof window !== 'undefined' ? window.dataLayer : undefined;

  const pushEvent = ({
    event = 'sendEvent',
    eventCategory = undefined,
    eventAction,
    eventLabel,
    eventValue = undefined,
  }) => {
    const productType = getLocalStorageState(PRODUCT_TYPE);
    let eventToSend = {
      event,
      eventCategory: eventCategory || productType,
      eventAction,
      eventLabel,
      eventValue,
      'user-Id': sessionStorage.getItem('uuid'),
    };
    if (typeof dataLayer !== 'undefined' && dataLayer?.push)
      dataLayer.push(eventToSend);
    if (
      config?.calculatorDomain?.includes('test') &&
      process.env.NODE_ENV !== 'test'
    )
      // eslint:disable-next-line
      // eslint-disable-next-line no-console
      console.log('(DEVELOP Only) Pushed event: ', eventToSend);
  };

  return [pushEvent];
}

export default useDataLayer;
