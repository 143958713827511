import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputGroupWrapper from './InputGroup';
import translatable from '../../utils/propTypes/translatable';
import IconButton from '../IconButton';

const Count = ({ form, field, className, icon }) => {
  // tslint:disable-next-line:radix
  const count = parseInt(field?.value);

  function add() {
    form?.setFieldValue(field.name, count + 1, false);
  }

  function remove() {
    if (count > 1) form?.setFieldValue(field.name, count - 1, false);
  }

  return (
    <div
      className={clsx(
        'w-200 hover:border-primary-400 h-50 px-20 flex justify-between items-center rounded border border-gray-200',
        className
      )}
    >
      <IconButton type="remove" onClick={remove} disabled={count === 1} />
      <div className="flex justify-between items-center text-xl font-bold text-gray-800">
        <span>{icon || null}</span>
        <span className="mx-8">x</span>
        <span>{count}</span>
        <input type="hidden" name={field.name} value={count} />
      </div>
      <IconButton type="add" onClick={add} />
    </div>
  );
};

Count.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  icon: PropTypes.any,
};

// eslint-disable-next-line react/no-multi-comp
const wrappedCount = props => (
  <InputGroupWrapper {...props} component={Count} />
);

wrappedCount.propTypes = {
  label: translatable,
  name: PropTypes.string.isRequired,
  placeholder: translatable,
};

export default wrappedCount;
