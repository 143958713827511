// React specific
import React, { FunctionComponent } from 'react';

// Icons
import PlaneIconSVG from '../assets/icons/plane-icon.inline.svg';

// Formatters
import { formatFlightTime } from '../utils/formatters';

// Third party
import clsx from 'clsx';

// Models
import { CustomerFlightModel } from '../models/CustomerFlightModel';
import { ValidationFlightModel } from '../models/ValidationFlightModel';
import Airport from '../components/Airport';
import { FunnelFlight } from './FlightSearch';

// CSS timeclass for styling
const timeClass = 'font-normal text-base text-gray-400 ml-8 hidden sm:inline';

/**
 *
 * This component dynamically returns a h2 airport stripe depending on the airports
 * @param flightProps
 * @returns JSX.Element
 */
const FlightAirportInformationStripe = ({
  flights,
  borderBottom,
  funnelFlight,
  children,
  className,
  style,
  removeDefaultStyles,
  element,
}: {
  flights: CustomerFlightModel[] | ValidationFlightModel[];
  borderBottom?: boolean;
  funnelFlight?: FunnelFlight;
  children?: any;
  className?: string;
  style?: any;
  removeDefaultStyles?: false;
  element?: string | FunctionComponent;
}): JSX.Element => {
  const Element = element || 'h2';

  return (
    <Element
      style={style}
      className={clsx(
        {
          ['sm:mt-30 mb-20 text-root sm:text-lg relative']: !removeDefaultStyles,
          ['sm:border-b pb-5 border-gray-200 ']: borderBottom,
        },
        className
      )}
    >
      {flights?.map(
        (
          flight: CustomerFlightModel | ValidationFlightModel,
          index: number
        ) => {
          const customerFlight = flight as CustomerFlightModel;
          const validationFlight = flight as ValidationFlightModel;

          const flightDepartureAirport =
            customerFlight?.departureAirport ??
            validationFlight?.flight?.departureAirport;
          const flightArrivalAirport =
            customerFlight?.arrivalAirport ??
            validationFlight?.flight?.arrivalAirport;

          const departure = (
            <>
              <Airport airport={flightDepartureAirport} />
              {funnelFlight && (
                <time className={timeClass}>
                  {formatFlightTime(funnelFlight.departure_time)}
                </time>
              )}
            </>
          );

          const arrival = (
            <>
              <Airport airport={flightArrivalAirport} />
              {funnelFlight && (
                <time className={timeClass}>
                  {formatFlightTime(funnelFlight.arrival_time)}
                </time>
              )}
            </>
          );

          const planeIcon = (
            <PlaneIconSVG
              className={clsx('mx-8 inline-block w-15 h-15', {
                ['fill-primary']: !removeDefaultStyles,
              })}
            />
          );

          return (
            // Condition is used to not show every arrival airport, just the last one!
            index < flights?.length - 1 ? (
              <span key={flight.id}>
                {departure}
                {planeIcon}
              </span>
            ) : (
              <span key={flight.id}>
                {departure}
                {planeIcon}
                {arrival}
                {children}
              </span>
            )
          );
        }
      )}
    </Element>
  );
};

export default FlightAirportInformationStripe;
