import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.check-compensation.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.check-compensation.description',
    defaultMessage: '',
  },
  paxHeader: {
    id: 'cc4.form.check-compensation.label.pax',
    defaultMessage: 'How many passengers were on your booking?',
  },
  submitBack: {
    id: 'cc4.form.check-compensation.button.back-button',
    defaultMessage: 'Back',
  },
  submitClaim: {
    id: 'cc4.form.check-compensation.button.submit-claim',
    defaultMessage: 'Check compensation',
  },
  yourEmailAddress: {
    id: 'cc4.form.check-compensation.label.email',
    defaultMessage: 'Your email address',
  },
  emailPlaceholder: {
    id: 'cc4.form.check-compensation.placeholder.email',
    defaultMessage: 'Enter your email',
  },
  asideText: {
    id: 'cc4.form.check-compensation.aside-text',
    defaultMessage:
      'Fill in these additional details and discover now your compensation!',
  },
  newsletterCheckbox: {
    id: 'cc4.form.check-compensation.newsletter-checkbox',
    defaultMessage: 'Keep me up to date on travellers’ rights and news',
  },
  checkCompensationWarning: {
    id: 'cc4.form.check-compensation.duplicate-warning',
    defaultMessage:
      "You've already filed a claim for this flight. Do you want to file another claim? (only possible for another booking with other passengers)",
  },
  checkCompensationModalConfirm: {
    id: 'cc4.form.check-compensation.modal-confirm',
    defaultMessage: 'Yes',
  },
  checkCompensationModalCancel: {
    id: 'cc4.form.check-compensation.modal-cancel',
    defaultMessage: 'No',
  },
  visaRadioLabel: {
    id: 'cc4.form.check-compensation.visa-radio-label',
    defaultMessage: 'Did you pay the flight with a Visa card?',
  },
  visaCardsLabel: {
    id: 'cc4.form.check-compensation.visa-cards-label',
    defaultMessage: 'Which card did you pay with?',
  },
  visaBanksLabel: {
    id: 'cc4.form.check-compensation.visa-banks-label',
    defaultMessage: 'Which bank issued the Visa card? (optional)',
  },
  placeholder: {
    id: 'cc4.form.check-compensation.placeholder.option',
    defaultMessage: 'Select an option',
  },
  otherOption: {
    id: 'cc4.form.check-compensation.visa-banks-other',
    defaultMessage: 'Other',
  },
});
