import { FunnelFlight } from '../../views/FlightSearch';
import { FlightType } from '../../models/CustomerFlightModel';

const SET_CUSTOMER_FLIGHT = 'SET_CUSTOMER_FLIGHT';
const REMOVE_CUSTOMER_FLIGHTS = 'REMOVE_CUSTOMER_FLIGHTS';
const HYDRATE_SELECT_VALUE = 'HYDRATE_SELECT_VALUE';
const SET_ACCOUNT = 'SET_ACCOUNT';

type ReduxAction = {
  type: string;
  payload: any;
};

export function reducer(state: any, action: ReduxAction) {
  const { type, payload } = action;
  switch (type) {
    case SET_CUSTOMER_FLIGHT: {
      const { index, flight, flightType } = payload;
      let flights = state?.customerFlights?.[flightType];

      if (index) {
        flights?.splice(index, 1, flight);
      } else {
        flights = [flight];
      }

      return {
        ...state,
        customerFlights: {
          ...state.customerFlights,
          [flightType]: flights,
        },
      };
    }

    case REMOVE_CUSTOMER_FLIGHTS: {
      const { index, flightType } = payload;
      const flights = state?.customerFlights?.[flightType];

      flights?.splice(index);

      return {
        ...state,
        customerFlights: {
          ...state.customerFlights,
          [flightType]: flights,
        },
      };
    }

    case HYDRATE_SELECT_VALUE: {
      return {
        ...state,
        [payload.entityName]: {
          ...state?.[payload.entityName],
          [payload.id]: payload.data,
        },
      };
    }

    case SET_ACCOUNT: {
      const { key, account } = payload;
      return {
        ...state,
        [key]: account,
      };
    }

    default:
      return state;
  }
}

/**
 * Set customer flight on index
 */
export const setCustomerFlight = (
  flight: FunnelFlight,
  index = 0,
  flightType: FlightType
) => ({
  type: SET_CUSTOMER_FLIGHT,
  payload: {
    index,
    flight,
    flightType,
  },
});

/**
 * Remove customer flights from index
 */
export const removeCustomerFlights = (index = 0, flightType: FlightType) => ({
  type: REMOVE_CUSTOMER_FLIGHTS,
  payload: {
    index,
    flightType,
  },
});

export const hydrateReactSelectValue = (
  entityName: string,
  id: number,
  data: any
) => ({
  type: HYDRATE_SELECT_VALUE,
  payload: { entityName, id, data },
});

export const setAccount = (key: string, account: object) => ({
  type: SET_ACCOUNT,
  payload: {
    key,
    account,
  },
});
