import { defineMessages } from 'react-intl';

export default defineMessages({
  itineraryHeaderTitle: {
    id: 'cc4.form.itinerary.header-text',
    defaultMessage: 'Your full flight itinerary',
  },
  itineraryContinue: {
    id: 'cc4.form.itinerary.continue-button-text',
    defaultMessage: 'Continue',
  },
  itineraryBack: {
    id: 'cc4.form.itinerary.back-button-text',
    defaultMessage: 'Back',
  },
  flightDisruption: {
    id: 'cc4.form.itinerary.disruption',
    defaultMessage: `According to our data the flight was {status, select,
      null {cancelled}
      other {delayed}
    }`,
  },
  saveFlight: {
    id: 'cc4.form.itinerary.save-text',
    defaultMessage: 'Save',
  },
  delayText: {
    id: 'cc4.form.itinerary.delay-text',
    defaultMessage: ` {days, plural,
      =0 {}
      one {1 day, }
      other {# days, }
    }{hours, plural,
      =0 {}
      one {1 hour and }
      other {# hours and }
    }{minutes, plural, 
      =0 {0 minutes}
      one {1 minute}
      other {# minutes}
    }`,
  },
  asideText: {
    id: 'cc4.form.itinerary.aside-text',
    defaultMessage:
      'We have helped millions of passengers with their disrupted flight for over 9 years',
  },
});
