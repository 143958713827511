import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import config from '../config';
import { getLocale } from '../utils/externalScripts';

const TrustMini = ({ className }: { className?: string }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className={clsx(
        'trustpilot-widget hidden lg:flex justify-center',
        className
      )}
      data-locale={getLocale(config)}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="62160cfa10aed4d5536d375f"
      data-style-height="150px"
      data-style-width="80%"
      data-theme="light"
      data-stars="4,5"
      data-review-languages="en"
    >
      <a
        href="https://uk.trustpilot.com/review/your-ce.com"
        target="_blank"
        rel="noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustMini;
