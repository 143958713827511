export default {
  primary05: 'rgba(38, 156, 116, 0.05)',
  primary10: '#c8e6dd',
  primary25: '#f1f7f4',
  primary50: '#f4faf8',
  primary100: '#e3fff1',
  primary200: '#93CDB9',
  primary300: '#93CDB9',
  primary400: '#269C74',
  primaryButtonText: '#ffffff',
  primaryButtonTextInverted: '#269c74',
  primaryCalendarIconFilter: 'none',
  primaryShadowTilePrimary: '0 1px 0 2px rgba(38, 156, 116, 0.6)',
  secondary05: 'rgba(38, 156, 116, 0.05)',
  secondary100: '#fff8e6',
  secondary200: '#FBD202',
  secondary300: '#F36B12',
  secondary400: '#F36B12',
  secondary500: '#c7580f',
  secondaryButtonText: '#ffffff',
  secondaryButtonTextInverted: '#269c74',
  h1: '#269C74',
  link: '#269C74',
};
