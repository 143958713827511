import { components } from 'react-select';
import PropTypes from 'prop-types';
import React from 'react';
import ArrowIcon from '../../../../assets/icons/arrow-icon.inline.svg';

const ControlWithBackButton = ({ children, ...props }) => {
  return (
    <div
      className={
        props?.isFocused
          ? 'flex border-b mx-10 border-gray-200 items-center'
          : null
      }
    >
      {props?.isFocused && (
        <ArrowIcon
          className={'fill-black ml-10'}
          style={{
            transform: 'rotate(180deg)',
          }}
          onClick={props?.selectProps?.field?.onBlur}
        />
      )}
      <div className="self-stretch flex-grow">
        <components.Control {...props}>{children}</components.Control>
      </div>
    </div>
  );
};

ControlWithBackButton.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object,
  hasValue: PropTypes.bool,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.object,
};

export default ControlWithBackButton;
