/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import '../../utils/yupIntlSetup';
import * as yup from 'yup';
import { navigate, useLocation } from '@reach/router';

import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import Card from '../../components/Card';
import messages from './messages';
import EnterFlightDisruption from './EnterFlightDisruption';
import FormikPersist from '../../components/fields/FormikPersist';
import StepNavigation from '../../components/StepNavigation';
import Container from '../../components/Container';
import Main from '../../components/Main';
import Aside from '../../components/Aside';
import PassengerIcon from '../../assets/icons/passenger-icon.inline.svg';
import StepTitle from '../../components/StepTitle';
import routes from '../../messages/routes';
import {
  steps,
  useTranslatedNavigate,
  translateRoute,
} from '../../utils/routes';
import { removeCustomerFlights } from '../../services/GlobalStore/reducer';
import useDataLayer from '../../hooks/useDataLayer';
import InfoOverlay from '../../components/InfoOverlay';
import TrustBox from '../../components/TrustBox';
import SocialProof from '../../components/SocialProof';
import SocialProofMobile from '../../components/SocialProof/Mobile';
import { addCustomerFlight } from '../../services/resources/customerFlights';
import Button from '../../components/Button';

/**
 * Checks if user should select alternative based on itinerary
 * @param flightDisruptions
 * @returns {*}
 */
export const shouldChooseAlternativeFlight = flightDisruptions =>
  flightDisruptions?.some(disruption =>
    ['canceled', 'denied', 'missed_connection'].includes(disruption)
  );

export const flightDisruptionMissing = flightDisruptions => {
  if (!flightDisruptions) return true;
  return flightDisruptions?.some(disruption => !disruption);
};

const sourcesToInternalStatuesMap = {
  flight_rerouted: 'diverted',
  cancelled: 'canceled',
};

export const mapFlightDataToSelectValue = flight => {
  if (flight?.status)
    return sourcesToInternalStatuesMap[flight.status] ?? flight.status;
  if (flight?.time_delayed) return 'delayed';
  return '';
};

const validationSchema = yup.object().shape({
  flightDisruptions: yup
    .array()
    .of(yup.string().required())
    .required(),
});

const Itinerary = () => {
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();
  const [pushEvent] = useDataLayer();
  const location = useLocation();

  const [customerFlights, dispatch] = useGlobalState('customerFlights.booked');

  useEffect(() => {
    if (!customerFlights?.length) {
      translatedNavigate('/');
    }
    // eslint-disable-next-line
  }, []);

  function handleSubmit(values) {
    if (values?.flightDisruptions?.length) {
      values.flightDisruptions.forEach((disruption, index) => {
        pushEvent({
          eventAction: steps.Itinerary,
          eventLabel: `flight-${index + 1}: ${disruption}`,
        });
      });
    }
    if (shouldChooseAlternativeFlight(values?.flightDisruptions)) {
      translatedNavigate(routes.alternativeFlight);
    } else {
      dispatch(removeCustomerFlights(0, 'alternative'));
      translatedNavigate(routes.checkCompensation);
    }
  }

  function navigateBack() {
    if (customerFlights?.length > 1) {
      translatedNavigate(`/flights/${customerFlights?.length}`);
    } else {
      translatedNavigate('/');
    }
  }

  const initialValues = {
    flightDisruptions: customerFlights?.map(mapFlightDataToSelectValue),
  };

  const createFlight = values => {
    const {
      airline_code,
      flight_number,
      flight_date,
      type,
      airline_id,
      departure_airport_id,
      arrival_airport_id,
      claimId,
      claim_number,
    } = customerFlights[0];

    const flight_code = `${airline_code}${flight_number}`;

    const formatFlight = {
      flight_date,
      type,
      flight_status: values?.flightDisruptions[0],
      airline: { id: airline_id },
      flight_number,
      departureAirport: { id: departure_airport_id },
      arrivalAirport: { id: arrival_airport_id },
      flight_code,
    };

    addCustomerFlight({
      ...formatFlight,
      claim: { id: claimId },
    });

    navigate(
      `/${translateRoute(routes.account)}/${translateRoute(
        routes.claim
      )}/${claim_number}/${translateRoute(routes.flightItinerary)}`
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values }) => (
        <Container>
          <Main>
            <Form>
              <Card onlyDesktop>
                <div className="flex justify-center sm:justify-between items-center">
                  <StepTitle>
                    {formatMessage(messages.itineraryHeaderTitle)}
                  </StepTitle>
                  <InfoOverlay
                    title={messages.itineraryHeaderTitle}
                    description={messages.asideText}
                  />
                </div>
                <div>
                  {customerFlights?.length &&
                    customerFlights?.map((flight, index) => (
                      <EnterFlightDisruption
                        // eslint-disable-next-line
                        key={`flight-${index}`}
                        flightIndex={index}
                        flight={flight}
                      />
                    ))}
                </div>
              </Card>
              {!location?.state?.type ? (
                <StepNavigation
                  onBackClick={navigateBack}
                  backButtonMessage={formatMessage(messages.itineraryBack)}
                  submitButtonMessage={formatMessage(
                    messages.itineraryContinue
                  )}
                  dataLayerPushOnBack
                  step={steps.Itinerary}
                />
              ) : (
                <Button
                  theme="primary"
                  type="button"
                  showIcon={false}
                  className="mt-40"
                  onClick={() => createFlight(values)}
                >
                  {formatMessage(messages.saveFlight)}
                </Button>
              )}
              <TrustBox />
              <SocialProofMobile />
              <FormikPersist
                localStorageKey={'flightDisruptions'}
                timeout={0}
              />
            </Form>
          </Main>
          <Aside showSocialProof>
            <SocialProof>
              <PassengerIcon className="mb-20" />
              {formatMessage(messages.asideText)}
            </SocialProof>
          </Aside>
        </Container>
      )}
    </Formik>
  );
};

export default Itinerary;
