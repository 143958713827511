import { useEffect } from 'react';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { useGlobalState } from './GlobalStore';

/**
 * Observes global context and saves it to local storage
 * @returns {null}
 * @constructor
 */
const StoreObserver = () => {
  const [state] = useGlobalState();

  const [, setLocalStorage] = useLocalStorageState('globalState', {});

  useEffect(() => {
    setLocalStorage(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state)]);

  return null;
};

export default StoreObserver;
