import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { NavigateOptions, useNavigate } from '@reach/router';

import translations from '../translations/main.json';

export function useTranslateRoutes(children: JSX.Element) {
  const pathsToTranslate = React.Children.map(
    children,
    child => child.props.path
  );
  return pathsToTranslate.map(route =>
    route?.id ? translateRoute(route) : route
  );
}

interface TranslatedNavigateOptions extends NavigateOptions<{}> {
  basepath?: string;
}

// TODO MAKE FUNCTION useTranslatedNavigate also work when there is an index/id
// ALSO I noticed some places which use this function without a basepath
// [steps.ConnectingFlights]: {
//   id: 'cc4.seo.route.connecting-flights',
//   defaultMessage: 'flights/:flightIndex',
// },
export function useTranslatedNavigate() {
  const navigate = useNavigate();
  return (
    route:
      | (MessageDescriptor | string)[]
      | MessageDescriptor
      | string
      | undefined,
    { basepath, ...rest }: TranslatedNavigateOptions = {}
  ) => {
    return navigate(translateRoute(route, { basepath }), rest);
  };
}

export function translateRoute(
  route: (MessageDescriptor | string)[] | MessageDescriptor | string,
  { basepath }: { basepath?: MessageDescriptor | string } = {}
): string {
  const path = Array.isArray(route) ? route : [route];

  return [basepath, ...path]
    .filter(Boolean)
    .map(chunk =>
      typeof chunk === 'string'
        ? chunk
        : chunk?.id
        ? translations[chunk.id]
        : undefined
    )
    .join('/')
    .replace(/^\/\//, '/');
}

export function normalizeRoute(path: string): string {
  if (!path) return '';
  return path[0] === '/' ? path : '/' + path;
}

export enum steps {
  TicketRefund = 'ticketRefund',
  FlightCompensation = 'flightCompensation',
  ConnectingFlights = 'connectingFlights',
  ReplacementFlight = 'replacementFlight',
  AlternativeFlight = 'alternativeFlight',
  Authorization = 'authorization',
  AlternativeFlights = 'alternativeFlights',
  CheckCompensation = 'checkCompensation',
  CongratulationsBasepath = 'congratulationsBasepath',
  CoBranding = 'cobranding',
  ClaimAmount = 'claimAmount',
  Permission = 'permission',
  PassengersList = 'passengersList',
  UploadDocuments = 'uploadDocuments',
  BookingReference = 'bookingReference',
  Login = 'login',
  AbandonedCart = 'abandonedCart',
  BankDetails = 'bankDetails',
  Sorry = 'sorry',
  Itinerary = 'itinerary',
  Questionnaire = 'questionnaire',
  Error = 'error',
  Passenger = 'passenger',
  PassengerDocuments = 'documents',
  ThankYou = 'thankYou',
  Claim = 'claim',
  Claims = 'claims',
  Documents = 'documents',
  ClaimsOverview = 'claimsOverview',
  ClaimDocuments = 'claimDocuments',
  BookingDetails = 'bookingDetails',
  FlightItinerary = 'flightItinerary',
  ConnectingFlight = 'connectingFlight',
  ClaimPassengerList = 'claimPassengerList',
  ClaimDetails = 'claimDetails',
  MainBooker = 'mainBooker',
  Minor = 'minor',
  BookingInfo = 'bookingInfo',
  Guardian = 'guardian',
  Account = 'account',
  Profile = 'myProfile',
  Password = 'password',
  MailUploadFlightDocuments = 'mailUploadFlightDocuments',
  HelpCenter = 'helpCenter',
  Messages = 'messages',
  UploadId = 'uploadId',
  UploadBookingConfirmation = 'uploadBookingConfirmation',
  UploadBoardingPass = 'uploadBoardingPass',
  UploadPaymentReceipt = 'uploadPaymentReceipt',
  UploadDelayConfirmation = 'uploadDelayConfirmation',
  UploadBirthCertificate = 'uploadBirthCertificate',
  UploadAdditionalCostAlternativeFlight = 'uploadAdditionalCostAlternativeFlight',
  UploadOtherDocument = 'uploadOtherDocument',
  ForgotPassword = 'forgot-password',
  SetNewPassword = 'set-new-password',
  Unsubscribe = 'unsubscribe',
  PaymentConfirmation = 'payment-confirmation',
  NoPaymentReceived = 'no-payment-received',
}
