import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FormGroup = props => {
  const { className, row, inline, children } = props;

  return (
    <div
      className={clsx(
        'flex flex-wrap mb-15 mt-30 md:mt-20',
        {
          'flex-col': !row,
          'items-center flex-row': row,
          'inline-flex': inline,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

FormGroup.defaultProps = {
  inline: false,
  row: false,
};

FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  inline: PropTypes.bool,
  row: PropTypes.bool,
};

export default FormGroup;
