/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import useSiteMetadata from '../hooks/useSiteConfig';
import { useIntl } from 'react-intl';
import messages from '../messages/common';
import translatable from '../utils/propTypes/translatable';
import config from '../config.json';
function Seo({ description, lang, title, path, image: metaImage }) {
  const { formatMessage } = useIntl();
  const {
    siteTitle,
    siteDescription,
    calculatorDomain,
    lang: siteConfigLang,
    twitterHandle,
    facebookLink,
    metaImage: siteConfigMetaImage,
    keywords,
  } = useSiteMetadata();

  const siteFullUrl = calculatorDomain.endsWith('/')
    ? calculatorDomain.substring(0, calculatorDomain.length - 1)
    : calculatorDomain;

  const metaDescription = description?.defaultMessage
    ? formatMessage(description)
    : siteDescription;

  const image = metaImage?.src
    ? `${calculatorDomain}${metaImage.src}`
    : siteConfigMetaImage;

  const htmlLangAttr = siteConfigLang || lang;

  const translatedTitle = title?.defaultMessage
    ? formatMessage(title)
    : siteTitle;
  const titleTemplate =
    messages?.seoTitleTemplate &&
    formatMessage(messages.seoTitleTemplate, { siteName: config.siteName });

  const metaKeywords = formatMessage(messages.seoKeywords) || keywords;

  return (
    // https://github.com/nfl/react-helmet/pull/246
    <Helmet defaultTitle={translatedTitle} titleTemplate={titleTemplate}>
      <html lang={htmlLangAttr} amp />
      <title itemProp="title" lang={htmlLangAttr}>
        {translatedTitle}
      </title>
      <meta name="description" content={metaDescription} />
      <meta property="keywords" content={metaKeywords} />

      {facebookLink ? (
        <meta property="article:publisher" content={facebookLink} />
      ) : null}

      {// Hot Module Reload won't work with that
      process.env.NODE_ENV === 'production' && (
        <>
          <base target="_blank" href={siteFullUrl + withPrefix(path)} />
          <link rel="canonical" href={siteFullUrl + withPrefix(path)} />
        </>
      )}

      {/* OpenGraph tags */}
      <meta property="og:url" content={siteFullUrl + withPrefix(path)} />
      <meta property="og:type" content={'website'} />
      <meta property="og:title" content={translatedTitle} />
      <meta property="og:description" content={metaDescription} />
      {image ? <meta property="og:image" content={image} /> : null}

      {/* Twitter Card tags */}
      <meta name="twitter:title" content={translatedTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {twitterHandle ? (
        <meta name="twitter:creator" content={twitterHandle} />
      ) : null}
      {/* Cannot use Fragments in React Helmet components so have to do it like this */}
      {image ? (
        <meta name="twitter:card" content="summary_large_image" />
      ) : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      {!image ? <meta name="twitter:card" content="summary" /> : null}
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'en',
  description: '',
  path: '',
};

Seo.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, translatable]),
  image: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ]),
  lang: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, translatable]),
};

export default Seo;
