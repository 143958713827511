const appLocalStoragePrefix = 'yo:';

export const TICKET_REFUND = 'ticket_refund';
export const FLIGHT_COMPENSATION = 'flight_compensation';
export const PRODUCT_TYPE = 'product_type';
export const CALCULATOR_TYPE = 'calculator_type';

export const getAppLocalData = key =>
  JSON.parse(localStorage.getItem(`${appLocalStoragePrefix}${key}`));

export const setAppLocalData = (key, value) =>
  localStorage.setItem(`${appLocalStoragePrefix}${key}`, JSON.stringify(value));

/**
 *
 * @param key {string}
 * @returns {any}
 */
export function getLocalStorageState(key) {
  const { value } =
    JSON.parse(localStorage.getItem(`${appLocalStoragePrefix}${key}`)) || {};
  return value;
}
