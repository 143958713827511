import React from 'react';
import { useIntl } from 'react-intl';
import config from '../../config.json';

// Images
// NL
import Nieuwsuur from '../../assets/images/social-proof/desktop/nieuwsuur.png';
import Nos from '../../assets/images/social-proof/desktop/nos.png';
import RTLNieuws from '../../assets/images/social-proof/desktop/rtl-nieuws.png';
import DeTelegraaf from '../../assets/images/social-proof/desktop/de-telegraaf.png';

// EN
import Reuters from '../../assets/images/social-proof/desktop/reuters.png';
import FinancialTimes from '../../assets/images/social-proof/desktop/financial-times.png';
import TheEconomist from '../../assets/images/social-proof/desktop/the-economist.png';
import TheGuardian from '../../assets/images/social-proof/desktop/the-guardian.png';

// FR
import LeParisien from '../../assets/images/social-proof/desktop/le-parisien.png';
import LesEchos from '../../assets/images/social-proof/desktop/les-echos.png';

// DE
import BerlinerMorgenpost from '../../assets/images/social-proof/desktop/berliner-morgenpost.png';
import Finanztip from '../../assets/images/social-proof/desktop/finanztip.png';
import BayerischerRundfunk from '../../assets/images/social-proof/desktop/bayerischer-rundfunk.png';

// ES
import Wired from '../../assets/images/social-proof/desktop/wired.png';

// IT
import LaRepubblica from '../../assets/images/social-proof/desktop/la-repubblica.png';
import CorriereDellaSera from '../../assets/images/social-proof/desktop/corriere-della-sera.png';

// Translations
import commonMessages from '../../messages/common';

const localeToImagesMap = {
  nl: [Nieuwsuur, DeTelegraaf, Nos, RTLNieuws],
  en: [Reuters, FinancialTimes, TheEconomist, TheGuardian],
  fr: [LeParisien, LesEchos, Reuters, FinancialTimes],
  de: [BerlinerMorgenpost, Finanztip, Reuters, BayerischerRundfunk],
  es: [Reuters, FinancialTimes, TheEconomist, Wired],
  it: [LaRepubblica, CorriereDellaSera, Reuters, FinancialTimes],
  pl: [Reuters, FinancialTimes, TheEconomist, Wired],
};

const SocialProof = props => {
  const { children } = props;
  const { formatMessage } = useIntl();
  const locale = config.locale;
  const images = localeToImagesMap[locale];

  if (images?.length) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="border-2 w-full border-gray-150" />
        <h2 className="my-15 text-gray-400">
          {formatMessage(commonMessages.socialProof)}
        </h2>
        <div className={`flex`}>
          <div className="flex flex-col">
            <img src={images[0]} />
            <img src={images[1]} />
          </div>
          <div className="flex flex-col ml-8">
            <img src={images[2]} />
            <img src={images[3]} />
          </div>
        </div>
      </div>
    );
  }
  return children ? children : null;
};

export default SocialProof;
