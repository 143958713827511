import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = {
  sm: 'shadow-sm py-25 px-12 rounded bg-white',
  noDesktop: 'sm:shadow-none sm:py-0 sm:px-0 sm:rounded-0 sm:bg-transparent',
  smallAndAbove: 'sm:rounded sm:shadow sm:px-40 sm:py-50 sm:bg-white',
  aside: 'sm:bg-white sm:rounded sm:shadow sm:py-50 sm:px-20',
};

// eslint-disable-next-line
const Card = ({
  aside,
  style,
  children,
  className,
  onlyMobile,
  onlyDesktop,
  tag,
}) => {
  const Tag = tag;
  return (
    <Tag
      style={style}
      className={clsx(
        {
          [styles.aside]: aside && !onlyMobile && !onlyDesktop,
          // Apply mobile classes and no desktop when onlyMobile
          [[styles.sm, styles.noDesktop].join(' ')]: onlyMobile,
          // Apply desktop classes when onlyDesktop
          [styles.smallAndAbove]: onlyDesktop && !onlyMobile,
          // Apply both classes when both enabled or both disabled (XNOR)
          [[styles.sm, styles.smallAndAbove].join(' ')]:
            !(onlyMobile ^ onlyDesktop) && !aside,
        },
        className
      )}
    >
      {children}
    </Tag>
  );
};

Card.defaultProps = {
  aside: false,
  onlyMobile: false,
  onlyDesktop: false,
  tag: 'div',
};

Card.propTypes = {
  aside: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onlyDesktop: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  style: PropTypes.object,
  tag: PropTypes.string,
};

export default Card;
