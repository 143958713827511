import { components } from 'react-select';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import React from 'react';

const HighlightedOption = ({ children, data, ...props }) => {
  let searchValue = props.selectProps?.inputValue;

  return (
    <components.Option {...props}>
      {data.leftLabel && (
        <span className="form-input__option-left-label">
          <Highlighter
            highlightTag={'b'}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={data.leftLabel}
          />
        </span>
      )}
      <Highlighter
        highlightTag={'b'}
        searchWords={[searchValue]}
        autoEscape
        textToHighlight={children}
      />
    </components.Option>
  );
};

HighlightedOption.propTypes = {
  children: PropTypes.string,
  data: PropTypes.object,
  selectProps: PropTypes.object,
};

export default HighlightedOption;
