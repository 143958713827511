import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import messages from './messages';
import alertIcon from '../../assets/icons/alert-green-icon.svg';

/**
 * Extracts minutes to units
 * @param {*} minutes
 */
const extractTimeUnits = minutes => {
  return {
    days: Math.floor(minutes / 1440),
    hours: Math.floor((minutes % 1440) / 60),
    minutes: minutes % 60,
  };
};

const DelayTile = ({ flight }) => {
  const { formatMessage } = useIntl();

  if (
    (!flight?.status && !flight?.time_delayed) ||
    flight?.status === 'on_time'
  ) {
    return null;
  }

  if (
    (flight?.time_delayed && flight?.time_delayed) < 120 &&
    !flight?.status?.includes('cancel')
  ) {
    return null;
  }

  return (
    <div
      className="mt-15 sm:mt-30 items-center content-center bg-no-repeat flex leading-snug h-50 sm:ml-30 w-315 rounded bg-primary-50 py-8 pr-15 pl-45 text-xs"
      style={{
        backgroundImage: `url(${alertIcon})`,
        backgroundPosition: 'left 1rem center',
      }}
    >
      <span>
        {formatMessage(messages.flightDisruption, {
          status: flight?.time_delayed,
        })}
        {flight?.time_delayed && (
          <>
            <br />
            <strong>
              {formatMessage(
                messages.delayText,
                extractTimeUnits(flight?.time_delayed)
              )}
            </strong>
          </>
        )}
      </span>
    </div>
  );
};

DelayTile.propTypes = {
  flight: PropTypes.object.isRequired,
};

export default DelayTile;
