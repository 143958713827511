import React from 'react';
import PropTypes from 'prop-types';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { defineMessages } from 'react-intl';

export const airlineSelectMessages = defineMessages({
  labelAirline: {
    id: 'cc4.form.field.label.airline.general',
    defaultMessage: 'Airline',
  },
  placeholderAirline: {
    id: 'cc4.form.field.placeholder.airline.general',
    defaultMessage: 'e.g. KLM or Air France',
  },
});

const buildAirlineValue = airline => ({
  value: airline.id,
  label: airline.name,
  ...airline,
});

const buildOrganizationValue = airline => ({
  value: airline.organization?.id,
  label: airline.name,
  ...airline,
});

const AirlineSelect = ({ asOrganization, ...props }) => (
  <ReactSelectWrapper
    {...props}
    endpoint="airlines"
    searchOnFilters={['search']}
    additionalQueryParams={{
      include: [...(asOrganization ? ['organization'] : [])],
    }}
    valueConstructor={
      asOrganization ? buildOrganizationValue : buildAirlineValue
    }
  />
);

AirlineSelect.defaultProps = {
  asOrganization: false,
  isClearable: true,
  label: airlineSelectMessages.labelAirline,
  placeholder: airlineSelectMessages.placeholderAirline,
};

AirlineSelect.propTypes = {
  asOrganization: PropTypes.bool,
  isClearable: PropTypes.bool,
  label: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.object,
};

export default AirlineSelect;
