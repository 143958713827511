import normalize from 'json-api-normalizer';
import { mergeWith, get, set, uniq } from 'lodash';

export const mergeCustomizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
};

// Action types
export const API_FAILURE = '@@API_FAILURE';
export const API_REQUEST = '@@API_REQUEST';
export const API_SUCCESS = '@@API_SUCCESS';

export default function jsonApiReducer(state = {}, action = {}) {
  const { type, payload, meta = {} } = action;

  switch (type) {
    case API_SUCCESS: {
      const oldState = state;
      const data = normalize(payload, {
        camelizeKeys: false,
        endpoint: meta.endpoint,
        ...meta.normalizeOptions,
      });
      // Normalized data has meta key with information about relationships
      // This is json-api-normalizer meta, not response meta
      let endpoint = Object.keys(data.meta)[0];
      set(
        data,
        `results.${endpoint}`,
        uniq([
          ...(get(state, `results.${endpoint}`) || []),
          ...((data &&
            data.meta[endpoint].data &&
            data.meta[endpoint].data.length &&
            data.meta[endpoint].data.map(datum => datum.id)) ||
            []),
        ])
      );
      return mergeWith(oldState, data, mergeCustomizer);
    }

    case API_FAILURE: {
      const oldState = state;
      const data = {};

      set(data, `errors.${meta.endpoint}`, payload);

      return mergeWith(oldState, data, mergeCustomizer);
    }

    default:
      return {
        ...state,
      };
  }
}
