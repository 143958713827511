import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          calculatorDomain
          siteLogo
          lang
          twitterHandle
          facebookLink
          metaImage
          keywords
        }
      }
    }
  `);
  return result.site.siteMetadata;
};

export default useSiteMetadata;
