import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import errorIcon from '../../assets/icons/error-icon.svg';
import translatable from '../../utils/propTypes/translatable';

const Error = ({ errorMessage }) => {
  const intl = useIntl();

  if (errorMessage) {
    return (
      <div className="flex items-center text-red-500 text-sm text-red-400 mt-10">
        <Icon src={errorIcon} className="w-15 h-15 mr-10" />
        {typeof errorMessage === 'object' && errorMessage?.id
          ? intl.formatMessage(errorMessage)
          : errorMessage}
      </div>
    );
  }

  return null;
};

Error.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.bool, translatable]),
};

export default Error;
