import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import BackIcon from '../assets/icons/back-icon.inline.svg';
import Button from './Button';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import useDataLayer from '../hooks/useDataLayer';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import messages from '../messages/common';

const StepNavigation = ({
  className = '',
  onBackClick,
  children,
  backButtonMessage,
  submitButtonMessage,
  dataLayerPushValueOnSubmit,
  dataLayerPushOnBack,
  dataLayerPushOnContinue,
  step,
  portalId,
  isNotCompleted = false,
  showIcon = true,
  disabled = false,
  isCustomerReceivables = false,
}) => {
  const { formatMessage } = useIntl();
  const { isValid, isSubmitting, values, submitForm } =
    useFormikContext() || {};

  const [pushEvent] = useDataLayer();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    portalId && setMounted(true);
    // eslint-disable-next-line
  }, []);

  function onBack() {
    onBackClick();
    if (dataLayerPushOnBack && process.env.NODE_ENV !== 'test') {
      pushEvent({
        eventLabel: 'back',
        eventAction: step,
      });
    }
  }

  function onSubmit() {
    if (dataLayerPushValueOnSubmit && process.env.NODE_ENV !== 'test') {
      pushEvent({
        eventLabel: get(values, dataLayerPushValueOnSubmit),
        eventAction: step,
      });
    } else if (dataLayerPushOnContinue && process.env.NODE_ENV !== 'test') {
      pushEvent({
        eventLabel: 'continue',
        eventAction: step,
      });
    }
    if (portalId) {
      submitForm();
    }
  }
  const checkDisabe = () => {
    if (isCustomerReceivables) {
      return disabled;
    }

    return !isValid || isSubmitting || isNotCompleted || disabled;
  };

  const component = (
    <div
      className={`${className} text-center mx-15 sm:mx-0 my-25 sm:my-40 mb-30 flex sm:inline-flex flex-col-reverse sm:flex-row w-auto sm:w-auto`}
    >
      {onBackClick && (
        <button
          type="button"
          data-testid="navigate-back"
          disabled={isSubmitting}
          onClick={onBack}
          className={clsx(
            'back-button flex-shrink-0 font-pt-sans text-lg flex sm:inline-flex items-center text-center justify-center button sm:border text-center rounded-full sm:w-50 h-50 text-gray-300 border-gray-300 hover:bg-gray-300 outline-none focus:outline-none',
            { 'opacity-50 cursor-default': isSubmitting }
          )}
        >
          <BackIcon className="hidden sm:inline" />
          <span className="sm:hidden outline-none focus:outline-none">
            {backButtonMessage || formatMessage(messages.back)}
          </span>
        </button>
      )}
      {children}
      <Button
        type="submit"
        onClick={onSubmit}
        theme="primary"
        disabled={checkDisabe()}
        isSubmitting={isSubmitting}
        showIcon={showIcon}
      >
        {submitButtonMessage}
      </Button>
    </div>
  );

  if (mounted && portalId) {
    const portal = document?.getElementById(portalId);
    return createPortal(component, portal);
  }

  return component;
};

StepNavigation.propTypes = {
  backButtonMessage: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  dataLayerPushOnBack: PropTypes.bool,
  dataLayerPushOnContinue: PropTypes.bool,
  dataLayerPushValueOnSubmit: PropTypes.string,
  onBackClick: PropTypes.func,
  portalId: PropTypes.string,
  showIcon: PropTypes.bool,
  step: PropTypes.string,
  submitButtonMessage: PropTypes.string,
};

export default StepNavigation;
