import base from './base';
import greenclaim from './greenclaim';
import tix from './tix';
import vliegtickets from './vliegtickets';
import wtc from './wtc';
import airrefund from './airrefund';
import gate1 from './gate1';
import flighttix from './flighttix';
import { IThemes } from './utils';

/**
 * The default theme to load
 */
export const DEFAULT_THEME: string = 'base';

export const themes: IThemes = {
  base,
  greenclaim,
  tix,
  vliegtickets,
  wtc,
  airrefund,
  gate1,
  flighttix,
};
