import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// Components
import InputGroupWrapper from './InputGroup';
import translatable from '../../utils/propTypes/translatable';

const InputField = ({
  id,
  name,
  inputClassName,
  type,
  placeholder,
  disabled,
  ...props
}) => {
  const intl = useIntl();
  return (
    <input
      id={id}
      data-hj-allow
      type={type}
      name={name}
      placeholder={
        placeholder?.id ? intl.formatMessage(placeholder) : placeholder
      }
      disabled={disabled}
      className={clsx(
        'form-input',
        'w-full',
        'placeholder-gray-300',
        {
          ['bg-gray-100 text-gray-300 border hover:border-gray-200']: disabled,
          'form-input-error': props.hasError,
          // 'sm:max-w-315': !props.full,
        },
        inputClassName
      )}
      aria-invalid={props.hasError}
      {...props.field}
    />
  );
};

InputField.defaultProps = {
  type: 'text',
};

InputField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  full: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  isTouched: PropTypes.bool,
  label: translatable,
  name: PropTypes.string,
  placeholder: translatable,
  type: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
const Input = props => <InputGroupWrapper {...props} component={InputField} />;

Input.propTypes = {
  label: translatable,
  name: PropTypes.string.isRequired,
  placeholder: translatable,
};

export default Input;
