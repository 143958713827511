import { components } from 'react-select';
import React from 'react';
import Icon from '../../../Icon';
import removeIcon from '../../../../assets/icons/remove-field-icon.svg';

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Icon src={removeIcon} />
  </components.ClearIndicator>
);
ClearIndicator.propTypes = {};

export default ClearIndicator;
