import { jsonApiAxios } from '../api';
import { CustomerFlightModel } from '../../models/CustomerFlightModel';

const relationshipToTypeMap = {
  arrivalAirport: 'airports',
  departureAirport: 'airports',
};

export const customerFlightsSchema = {
  attributes: [
    'type',
    'flight_code',
    'flight_date',
    'flight_status',
    'missed_flight',
    'arrivalAirport',
    'departureAirport',
    'airline',
    'claim',
  ],
  claim: { ref: 'id' },
  arrivalAirport: { ref: 'id' },
  departureAirport: { ref: 'id' },
  airline: { ref: 'id' },
  typeForAttribute: attribute => relationshipToTypeMap[attribute],
};

export function addCustomerFlight(values: CustomerFlightModel) {
  return jsonApiAxios.post('/customerFlights', values, {
    schema: customerFlightsSchema,
    type: 'customerFlights',
  });
}
