import { jsonApiAxios } from '../api';
import { omit } from 'ramda';

const relationshipToTypeMap = {
  person: 'persons',
};

export const passengersSchema = {
  attributes: ['ticket_number', 'booking_number', 'claim', 'person'],
  claim: {
    ref: 'id',
  },
  person: {
    ref: 'id',
  },
  typeForAttribute: attribute => relationshipToTypeMap[attribute],
};

export function createPassenger(values) {
  return jsonApiAxios.post('/passengers?include=person', omit(['id'], values), {
    schema: passengersSchema,
    type: 'passengers',
  });
}

export const updatePassenger = ({ passenger, values, config = {} }) => {
  return jsonApiAxios.patch(
    `/passengers/${passenger.id}?include=person`,
    values,
    {
      schema: passengersSchema,
      type: 'passengers',
      ...config,
    }
  );
};

export function deletePassenger({ passengerId, config }) {
  return jsonApiAxios.delete(`/passengers/${passengerId}`, config);
}
