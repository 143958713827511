import React from 'react';
import config from '../../config.json';

// Images
// NL
import Nieuwsuur from '../../assets/images/social-proof/mobile/nieuwsuur.png';
import Nos from '../../assets/images/social-proof/mobile/nos.png';
import DeTelegraaf from '../../assets/images/social-proof/mobile/de-telegraaf.png';

// EN
import Reuters from '../../assets/images/social-proof/mobile/reuters.png';
import FinancialTimes from '../../assets/images/social-proof/mobile/financial-times.png';
import TheGuardian from '../../assets/images/social-proof/mobile/the-guardian.png';

// FR
import LeParisien from '../../assets/images/social-proof/mobile/le-parisien.png';
import LesEchos from '../../assets/images/social-proof/mobile/les-echos.png';

// DE
import BerlinerMorgenpost from '../../assets/images/social-proof/mobile/berliner-morgenpost.png';
import Finanztip from '../../assets/images/social-proof/mobile/finanztip.png';

// ES
import TheEconomist from '../../assets/images/social-proof/mobile/the-economist.png';

// IT
import LaRepubblica from '../../assets/images/social-proof/mobile/la-repubblica.png';
import CorriereDellaSera from '../../assets/images/social-proof/mobile/corriere-della-sera.png';

const localeToImagesMap = {
  nl: [Nieuwsuur, DeTelegraaf, Nos],
  en: [Reuters, FinancialTimes, TheGuardian],
  fr: [LeParisien, LesEchos, Reuters],
  de: [BerlinerMorgenpost, Reuters, Finanztip],
  es: [Reuters, FinancialTimes, TheEconomist],
  it: [LaRepubblica, CorriereDellaSera, Reuters],
  pl: [Reuters, FinancialTimes, TheEconomist],
};

const SocialProof = () => {
  const locale = config.locale;
  const images = localeToImagesMap[locale];

  if (images?.length) {
    return (
      <div className={`sm:hidden flex justify-between mt-20 mb-32`}>
        <img src={images[0]} />
        <img src={images[1]} />
        <img src={images[2]} />
      </div>
    );
  }
  return null;
};

export default SocialProof;
