import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import InputMask from 'react-input-mask';
import config from '../../../config';

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

require(`moment/locale/${locale[config.locale] || 'en-gb'}`);

import calendarIcon from '../../../assets/icons/calendar-icon.svg';
import translatable from '../../../utils/propTypes/translatable';
import InputGroupWrapper from '../InputGroup';

const format = 'YYYY-MM-DD';
const userFormat = 'DD/MM/YYYY';

const messages = defineMessages({
  datetimePickerPlaceholder: {
    id: 'cc4.form.field.label.date.general',
    defaultMessage: 'Select date',
  },
});

const calendarIconStyles = {
  backgroundImage: `url(${calendarIcon})`,
  backgroundRepeat: 'no-repeat',
  transform: 'translateX(-2rem)',
};

const DatePicker = ({
  autoComplete,
  className,
  placeholder,
  dateFormat,
  disabled,
  timeFormat,
  hasError,
  name,
  showError,
  setShowError,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { form, field, meta } = props;
  const [isMounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const value = useMemo(() => {
    let inputValue = field?.value;
    if (inputValue) {
      if (moment(inputValue, 'YYYY-MM-DD', true).isValid()) {
        return moment(inputValue);
      }
      return inputValue;
    }
    return null;
  }, [field]);

  const handleChange = nextValue => {
    if (setShowError) {
      setShowError('');
    }
    form?.setFieldTouched(field.name);
    if (nextValue instanceof moment) {
      form?.setFieldValue(field.name, nextValue.format(format));
    } else if (nextValue?.target?.value) {
      if (moment(nextValue?.target?.value, userFormat, true).isValid()) {
        form?.setFieldValue(
          field.name,
          moment(nextValue?.target?.value, userFormat, true).format(format)
        );
      } else if (nextValue?.target?.value !== '__/__/____') {
        form?.setFieldValue(field.name, nextValue?.target?.value);
      } else form?.setFieldValue(field.name, null);
    }
  };

  const handleOnBlur = () => {
    let value = field?.value;
    let error = meta.error;
    if (value) {
      if (moment(value, 'YYYY-MM-DD', true).isValid()) {
        setShowError('');
        return moment(value);
      }
      // if user leaves field with incomplete date
      setShowError(error);
    }
    // if user leaves field empty
    setShowError(error);
    return null;
  };

  const classes = clsx(
    'form-input',
    'w-datepicker z-0',
    'placeholder-gray-300',
    className,
    {
      ['bg-gray-100 text-gray-300 border hover:border-gray-200']: disabled,
      'form-input-error': showError,
    }
  );

  // eslint-disable-next-line react/no-multi-comp
  function renderInput(
    // tslint:disable-next-line:no-shadowed-variable
    props,
    openCalendar
  ) {
    return (
      <div className={'flex items-center'}>
        <InputMask
          {...props}
          mask="99/99/9999"
          placeholder={
            placeholder?.id ? formatMessage(placeholder) : placeholder
          }
          className={classes}
          // eslint-disable-next-line
          onChange={handleChange}
          // eslint-disable-next-line
          onBlur={handleOnBlur}
          onClick={undefined}
          onFocus={undefined}
          disabled={disabled}
          data-hj-allow
        />
        <span
          className="flex-shrink-0 primary-filter hover:opacity-75 cursor-pointer w-15 h-15 inline-block z-10 -translate-x-10"
          style={calendarIconStyles}
          onClick={disabled ? null : openCalendar}
        />
      </div>
    );
  }

  if (isMounted && !isMobile) {
    return (
      <DateTime
        {...props}
        id={field?.name}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        value={value}
        // eslint-disable-next-line
        onChange={handleChange}
        autoComplete={autoComplete}
        closeOnSelect={true}
        renderInput={renderInput}
        disabled={disabled}
        locale={locale[config.locale]}
      />
    );
  }

  return (
    <input
      data-hj-allow
      type="date"
      name={name}
      disabled={disabled}
      className={classes}
      aria-invalid={hasError}
      placeholder={placeholder?.id ? formatMessage(placeholder) : placeholder}
      autoComplete={autoComplete}
      style={{
        ...calendarIconStyles,
        backgroundPosition: 'right 1rem center',
        transform: 'none',
      }}
      {...props.field}
    />
  );
};

DatePicker.defaultProps = {
  autoComplete: 'on',
  dateFormat: 'DD/MM/YYYY',
  timeFormat: false,
  placeholder: messages.datetimePickerPlaceholder,
};

DatePicker.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  disabled: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  formik: PropTypes.object,
  handleChange: PropTypes.func,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  isTouched: PropTypes.bool,
  label: translatable,
  meta: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: translatable,
  setShowError: PropTypes.string,
  showError: PropTypes.string,
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
};

export { DatePicker };

// eslint-disable-next-line react/no-multi-comp
const wrappedDatePicker = props => (
  <InputGroupWrapper {...props} component={DatePicker} />
);

export default wrappedDatePicker;
