export default {
  primary05: 'rgba(250, 192, 21, 0.05)',
  primary25: '#FFFCF4',
  primary50: '#F4FAF8',
  primary100: '#fff8e6',
  primary200: '#eed16e',
  primary300: '#facd42',
  primary400: '#ff4600',
  primary500: '#FFE187',
  primaryButtonText: '#000000',
  primaryButtonTextInverted: '#ff4600',
  primaryCalendarIconFilter:
    'invert(45%) sepia(93%) saturate(5173%) hue-rotate(1deg) brightness(103%) contrast(105%)',
  primaryShadowTilePrimary: '0 1px 0 2px rgba(25, 94, 226, 1)',
  secondary05: 'rgba(250, 192, 21, 0.05)',
  secondary25: '#195ee2',
  secondary50: '#F4FAF8',
  secondary100: '#fff8e6',
  secondary200: '#fbd202',
  secondary300: '#ff4600',
  secondary400: '#ff4600',
  secondary500: '#195ee2',
  secondaryButtonText: '#000000',
  secondaryButtonTextInverted: '#fac015',
  h1: '#ff4600',
  link: '#000000',
};
