import React, { ReactNode } from 'react';
import { stringify } from 'qs';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Serializer, Deserializer } from 'jsonapi-serializer';
import config from '../config';
import translations from '../translations/main.json';
import { pluck } from 'ramda';
import { useIntl } from 'react-intl';
import * as Sentry from '@sentry/browser';

import httpErrorMessages from '../messages/httpErrorMessages';
import { clearPart2Data } from '../views/CompensationCheck';
import { useModal } from '../components/Modals/ModalContext';
import { navigate } from '@reach/router';
import { useTranslatedNavigate } from '../utils/routes';

export const JSONAPI_CONTENT_TYPE = 'application/vnd.api+json';

export * from 'axios';

export interface ApiRequestConfig extends AxiosRequestConfig {
  noSerialize?: boolean;
  type: string;
  schema: object;
}

/**
 * Gets api url dynamically. If co-branding site we take it frm configs dir:
 * otherwise just config.json in src dir
 * @returns {Promise<*>}
 */
export const getApiRoot = async () => {
  const partner = sessionStorage.getItem('partner');
  const { apiUrl } = await import(
    `../${partner ? `configs/${partner}` : 'config'}.json`
  );
  return apiUrl;
};

const jsonApiAxios = axios.create({
  withCredentials: true,
  headers: {
    Accept: JSONAPI_CONTENT_TYPE,
    'Accept-Language': config.locale,
    'Content-Type': JSONAPI_CONTENT_TYPE,
  },
  transformRequest: data => data,
});

async function requestCookie() {
  if (document.cookie.includes('XSRF-TOKEN')) {
    // eslint-disable-next-line
    return new Promise(resolve => resolve());
  }
  return jsonApiAxios.get('/csrf-cookie');
}

jsonApiAxios.interceptors.request.use(
  async (requestConfig: any) => {
    requestConfig.baseURL = await getApiRoot();
    // Before every post request we want to get and set XSRF Token
    // Axios will do that for us because withCredentials is set to true

    if (
      requestConfig?.method &&
      ['post', 'patch'].includes(requestConfig?.method)
    ) {
      return requestCookie().then(response => {
        return {
          ...requestConfig,
          data:
            requestConfig.headers['Content-Type'] === JSONAPI_CONTENT_TYPE
              ? !requestConfig?.noSerialize
                ? JSON.stringify(
                    new Serializer(requestConfig.type, {
                      ...(requestConfig.schema || {}),
                      keyForAttribute: attribute => attribute,
                    }).serialize(requestConfig.data)
                  )
                : JSON.stringify(requestConfig.data)
              : requestConfig.data,
        };
      });
    } else return requestConfig;
  },
  error => {
    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  }
);

let tryouts = 0;
const MAX_TRYOUTS = 5;

export function clearAllCookies() {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookie.split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      const p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

/**
 * Deserialize each response
 */
jsonApiAxios.interceptors.response.use(
  (value: any) => {
    if (
      value?.data &&
      value?.headers?.['content-type'].includes(JSONAPI_CONTENT_TYPE) &&
      Object.keys(value?.data).length > 0
    ) {
      // this is for new submit claim endpoint as we do not need to deserialize
      if (value?.data['atomic:results']?.length) {
        return value;
      }
      return deserializeJSONAPI(value.data);
    }
    return value;
  },
  error => {
    const { responseText } = error.request ?? {};
    const { errorResponse } = error?.response ?? {};
    // if (
    //   error?.response?.status === 422 ||
    //   (error?.response?.config?.url?.includes('signableLetters') &&
    //     error?.response?.config?.data?.includes('partner-power-of-attorney'))
    // ) {
    //   // eslint-disable-next-line
    //   return Promise.reject(error);
    // }

    Sentry?.captureMessage(makeApiSentryErrorTitle(error), {
      tags: {
        url: errorResponse?.url,
        method: errorResponse?.method,
      },
      level: mapErrorCodeToLevel(error?.response?.status),
      extra: {
        cookie: document.cookie,
        responseText,
        requestPayload: error?.config?.data,
        flightDisruptions: localStorage.getItem('yo:flightDisruptions'),
      },
    });
    if (error?.response?.status === 419 && tryouts < MAX_TRYOUTS) {
      tryouts++;
      clearAllCookies();
      return jsonApiAxios.get('/csrf-cookie').then(() => {
        if (document.cookie) {
          return jsonApiAxios.request({
            ...error.config,
            noSerialize: true,
            data: JSON.parse(error.config.data),
          });
        } else {
          alert(
            'Seems like your cookies are disabled for this page. Please enable cookies to proceed.'
          );
          return error;
        }
      });
    } else {
      tryouts = 0;
      // eslint-disable-next-line
      return Promise.reject(error);
    }
  }
);

export function deserializeJSONAPI(data: any) {
  return data?.data
    ? new Deserializer({
        keyForAttribute: 'snake_case',
      }).deserialize(data)
    : undefined;
}

export { jsonApiAxios };

export const stringifyJsonApiParams = (params: any) =>
  stringify(params, { encode: false, strictNullHandling: true });

/**
 * Takes json you can copypaste from browser response and arms it with what
 * apiClient would do
 * @param json
 * @returns {{response : {content : *, response : Response}}}
 */
export const apiTestHelperWrapper = (json: any) => ({
  response: {
    content: json,
    response: new Response(),
  },
});

// use-http config
export const useHttpOptions = {
  interceptors: {
    // every time we make an http request, this will run 1st before the request is made
    // url, path and route are supplied to the interceptor
    // request options can be modified and must be returned
    request: async ({ options }: any) => {
      if (!document.cookie.includes('XSRF-TOKEN')) {
        await jsonApiAxios.get('/csrf-cookie');
      }
      const token = document.cookie.replace(
        /(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      );
      return {
        ...options,
        headers: {
          ...options.headers,
          'X-XSRF-TOKEN': decodeURIComponent(token),
        },
      };
    },
    response: async (props: any) => {
      if (props.response.status === 401) {
        localStorage.clear();
        navigate(['', translations['cc4.seo.route.login']].join('/'));
      }
      return props.response;
    },
  },
};

export const relateBySelectValue = (allAttributes: any, value: any) =>
  value || undefined;

export const getResponseErrors = (error: any) => {
  if (error?.response?.data?.errors) {
    return pluck('detail' as any, error.response.data.errors);
  }
};

export const useHandleError = () => {
  const { formatMessage } = useIntl();
  const [showModal] = useModal('default');
  const translatedNavigate = useTranslatedNavigate();

  const handleError = (error: any) => {
    if (error?.response?.status) {
      if (httpErrorMessages[error.response.status]) {
        if (error?.response?.status === 422) {
          showModal({
            title: formatMessage(httpErrorMessages[error.response.status]),
            body: (
              <ul className="text-center">
                {getResponseErrors(error)?.map((detail: unknown, index) => (
                  // eslint-disable-next-line
                  <li key={`error-${index}`} data-before={'-'}>
                    {detail as string}
                  </li>
                ))}
              </ul>
            ),
            onCancelText: formatMessage(httpErrorMessages.errorModalCloseText),
          });
        } else if (error?.response?.status === 401) {
          alert(formatMessage(httpErrorMessages[error.response.status]));
          localStorage.clear();
          translatedNavigate(['/', translations['cc4.seo.route.login']]);
        } else {
          alert(formatMessage(httpErrorMessages[error.response.status]));
        }
        if (error?.response?.status === 500) {
          clearPart2Data();
          clearAllCookies();
          [window.location as any] = '/500';
        }
      } else alert(formatMessage(httpErrorMessages.general));
    }
  };

  return [handleError];
};

const makeApiSentryErrorTitle = (error: any) => {
  const env = config.portalUrl.includes('test') ? 'TEST' : 'PROD';
  const code = error?.response?.status ?? 'No response';
  const url =
    error?.config?.url
      ?.replace(/\?(.+)/, '')
      .replace(/auth\/(.+)/, 'auth/:hash')
      .replace(/(\/\d+($|\/))/g, '/:id$2') ?? '';
  return `[${env}] API:${code} ${url}`;
};

const mapErrorCodeToLevel = (code: string | undefined) => {
  if (!code) return Sentry.Severity.Fatal;
  if (Number(code) > 500) return Sentry.Severity.Fatal;
  if (Number(code) === 422) return Sentry.Severity.Info;
  return Sentry.Severity.Error;
};
