import { jsonApiAxios } from '../api';

export const customersSchema = {
  attributes: [
    'email',
    'firstname',
    'lastname',
    'birthday',
    'streetname',
    'postal',
    'city',
    'phone',
    'country',
  ],
  country: {
    ref: 'id',
  },
};

export function createCustomer(values) {
  return jsonApiAxios.post('/customers', values, {
    schema: customersSchema,
    type: 'customers',
  });
}

export function updateCustomer(id, values) {
  return jsonApiAxios.patch(`/customers/${id}`, values, {
    schema: customersSchema,
    type: 'customers',
  });
}
