import React from 'react';
import clsx from 'clsx';
import translation from '../translations/main.json';

const Main = ({
  className,
  children,
}: {
  className?: string;
  children: any;
}) => {
  // Checking if is support page because we need to set max width to Card component
  const isHelpPage =
    typeof window !== 'undefined' &&
    (window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    ) === translation['cc4.seo.route.help-center'] ||
      window.location.pathname.substring(
        window.location.pathname.lastIndexOf('/') + 1
      ) === 'cc4.account-page.help.message-all-answers-route');
  const maxWidth = !isHelpPage ? 'max-w-770' : '';

  return (
    <section className={clsx(`flex-auto ${maxWidth}`, className)}>
      {children}
    </section>
  );
};

export default Main;
