import { components } from 'react-select';
import PropTypes from 'prop-types';
import React from 'react';

const ValueWithIcon = ({ children, ...props }) => {
  const {
    selectProps: { hideIconOnValue },
    hasValue,
  } = props;

  return (
    <>
      {hideIconOnValue && hasValue
        ? null
        : props?.selectProps?.icon &&
          // Icon()
          React.cloneElement(props?.selectProps?.icon, {
            className: 'fill-primary inline mr-8',
          })}
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </>
  );
};

ValueWithIcon.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object,
  hasValue: PropTypes.bool,
  selectProps: PropTypes.object,
};

export default ValueWithIcon;
