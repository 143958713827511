import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { reducer } from './reducer';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import get from 'lodash/get';
import size from 'lodash/size';
import build from 'redux-object';
import useAuthenticate from '../../hooks/useAuthenticate';

import StoreObserver from './StoreObserver';
import useFetch from 'use-http';
import jsonApiReducer, { API_FAILURE, API_SUCCESS } from './jsonApiReducer';
import { stringifyJsonApiParams } from '../api';

const initialState = { api: {} };
const GlobalStoreContext = createContext(initialState);
const { Provider } = GlobalStoreContext;

/**
 * Returns whole or partial state
 * @param path {string} path of the store to get
 * @returns {*[]}
 */
export const useGlobalState = (path = undefined) => {
  const { state, dispatch } = useContext(GlobalStoreContext);
  return useMemo(() => {
    return [path ? get(state, path) : state, dispatch];
  }, [path, dispatch, state]);
};

export const useGlobalApi = () => useGlobalState('api');

export function useBuild(entity, id) {
  const [state] = useGlobalState('api');
  return build(state, entity, id);
}

const StateProvider = ({ children }) => {
  const [localStorageState] = useLocalStorageState('globalState', {});
  const [state, dispatchToReactState] = useReducer(reducer, {
    ...initialState,
    ...localStorageState,
  });
  let apiState = state?.api || {};
  const [api, dispatchToApi] = useReducer(jsonApiReducer, apiState);

  const dispatch = action => {
    dispatchToReactState(action);
    dispatchToApi(action);
    // eslint-disable-next-line no-undef
    return new Promise(resolve => resolve(action));
  };

  return (
    <Provider value={{ state: { ...state, api }, dispatch }}>
      <StoreObserver />
      {children}
    </Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.any,
};

export { StateProvider };

function useJsonApi() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { authMethod, token } = useAuthenticate();
  let [meta, dependency] = useMemo(() => {
    let [, initialMeta, dependencyCandidate] = arguments;
    let newMeta = {};
    // If no meta and dependency is second create config object
    if (!(initialMeta instanceof Array)) {
      // Else we for sure have meta as second argument
      if (authMethod === 'token') {
        newMeta = {
          ...initialMeta,
          headers: { Authorization: `Bearer ${token}` },
        };
      } else {
        newMeta = {
          ...initialMeta,
          credentials: 'include',
        };
      }
      return [newMeta, dependencyCandidate];
    }
    newMeta = {
      ...newMeta,
      credentials: 'include',
    };
    if (authMethod === 'token') {
      newMeta = {
        ...initialMeta,
        headers: { Authorization: `Bearer ${token}` },
      };
    }
    return [newMeta, arguments[1]];
  }, [authMethod, token]);

  let filterEndpoint = get(meta, 'normalizeOptions.filterEndpoint');

  let endpoint =
    filterEndpoint === true ? arguments[0].replace(/\?.*$/, '') : arguments[0];

  if (meta?.queryParams) {
    endpoint += '?' + stringifyJsonApiParams(meta.queryParams);
  }

  const newArgs = [endpoint, meta, dependency].filter(Boolean);

  const { data, loading, ...rest } = useFetch(...newArgs);

  const { state, dispatch } = useContext(GlobalStoreContext);

  useEffect(() => {
    if (loading) {
      setIsLoaded(false);
    } else if (!loading && data?.data) {
      setIsLoaded(true);
    }
    // eslint-disable-next-line
  }, [loading]);

  if (!loading && data?.data) {
    dispatch({
      type: API_SUCCESS,
      payload: data,
      meta: {
        endpoint,
        normalizeOptions: {
          filterEndpoint: !!filterEndpoint,
        },
      },
    });
  } else if (!loading && data?.errors) {
    dispatch({
      type: API_FAILURE,
      payload: data,
      meta: {
        endpoint,
        normalizeOptions: {
          filterEndpoint: !!filterEndpoint,
        },
      },
    });
  }
  return {
    ...rest,
    loading,
    isLoaded,
    data: getItemsByMeta(state?.api, arguments[0], meta),
    meta: data?.meta,
    errors: data?.errors,
  };
}

const getItemsByMeta = (apiObject, endpoint, meta) => {
  const path = endpoint.split('?');

  const data = get(apiObject, [
    'meta',
    path[0],
    ...[
      path[1]
        ? '?' + path[1]
        : [
            meta?.queryParams
              ? '?' + stringifyJsonApiParams(meta.queryParams)
              : '',
          ],
    ],
    'data',
  ]);
  if (!size(data)) {
    return data;
  }
  // relationship name can be different than related entity
  let relatedEntityName = Array.isArray(data) ? data[0].type : data.type;
  return build(
    apiObject,
    relatedEntityName,
    Array.isArray(data) ? data.map(({ id }) => id) : data.id,
    {},
    {},
    data.meta
  );
};

export { useJsonApi };

export default GlobalStoreContext;
