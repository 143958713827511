import React, { useEffect, useRef, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { size } from 'lodash';
import { isMobile } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';

import Button from '../../components/Button';
import searchFlightForm from '../messages/searchFlightForm';
import Alert from '../../components/Alert';
import SingleResult from './SingleResult';
import Loading from '../../components/Loading';
import useDataLayer from '../../hooks/useDataLayer';
import useFlightSearch from './useFlightSearch';
import { FlightType } from '../../models/CustomerFlightModel';

interface ResultFlight {
  scheduled_gate_departure_time: string;
  departure_time: string;
  airline_code: string;
  departure_date: string;
}

interface MergedResults {
  [key: string]: ResultFlight;
}

const sortFlights = (
  [keyA, a]: [string, ResultFlight],
  [keyB, b]: [string, ResultFlight]
) => {
  const aDate = keyA.substr(0, 10);
  const bDate = keyB.substr(0, 10);

  if (aDate !== bDate) {
    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
  }

  const aTime = a.scheduled_gate_departure_time || a.departure_time;
  const bTime = b.scheduled_gate_departure_time || b.departure_time;

  if (aTime && bTime) {
    if (aTime < bTime) {
      return -1;
    }
    if (aTime > bTime) {
      return 1;
    }
  }
  return 0;
};

export const sortMergedResults = (data: MergedResults) =>
  Object.entries(data).sort(sortFlights);

type SearchByCodeValues = {
  flight_code: string;
  departure_date: string;
};

interface ResultsListProps {
  flightIndex: number;
  flightType: FlightType;
  isFlightCodeShown: boolean;
  attemptsCount: number;
  step: string;
  values: SearchByCodeValues;
  incrementAttempts: () => void;
  showFlightCodeField: () => void;
}

const ResultsList = ({
  values,
  flightIndex,
  flightType,
  isFlightCodeShown,
  step,
  attemptsCount,
  incrementAttempts,
  showFlightCodeField,
}: ResultsListProps) => {
  const resultRef = useRef(null);
  const resultsRef = useRef(null);

  const [pushEvent] = useDataLayer();

  const scrollToResults = useCallback(() => {
    resultsRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [resultsRef]);

  const { isValid, isSubmitting, submitForm, resetForm } =
    useFormikContext() || {};

  function flightNotVisibleClick() {
    resetForm();
    incrementAttempts();
    pushEvent({ eventLabel: 'my flight is not displayed', eventAction: step });
  }

  function noResultsClick() {
    if (attemptsCount === 0 && !isFlightCodeShown) {
      showFlightCodeField();
    }
    resetForm();
    incrementAttempts();
    pushEvent({ eventLabel: 'check again', eventAction: step });
  }

  const { data, isLoading } = useFlightSearch({
    isFlightCodeShown,
    values,
    flightIndex,
    flightType,
  });

  useEffect(() => {
    if (!isMobile || !size(data)) return;
    const timer = setTimeout(scrollToResults, 400);
    return () => clearTimeout(timer);
  }, [data, scrollToResults, resultsRef]);

  const { formatMessage } = useIntl();

  if (!isValid) {
    return null;
  }

  if (isLoading && size(data) === 0) {
    return (
      <div className="flex justify-center mt-40">
        <Loading />
      </div>
    );
  }

  if (!isLoading && size(data) === 0) {
    return (
      <div className="mt-40">
        <Alert level="danger">
          {attemptsCount === 0 && !isFlightCodeShown
            ? [formatMessage(searchFlightForm.searchFlightsNoResultsFirst)]
            : [formatMessage(searchFlightForm.searchFlightsNoResults)]}
        </Alert>
        <div className="mt-40">
          <Button type="button" theme="secondary-fill" onClick={noResultsClick}>
            {formatMessage(
              attemptsCount === 0 && !isFlightCodeShown
                ? searchFlightForm.provideFlightCode
                : attemptsCount >= 1
                ? searchFlightForm.enterFlightManually
                : searchFlightForm.checkMyFlight
            )}
          </Button>
        </div>
      </div>
    );
  }

  // TODO: Parent component flight search has isFormValid which unmounts this
  //  component so exit transition is not triggered
  return (
    <CSSTransition
      in={Boolean(size(data))}
      timeout={{
        appear: 400,
        enter: 400,
        // exit: 2000
      }}
      classNames="show-results-"
      unmountOnExit={true}
      mountOnEnter={true}
      appear={true}
      enter={true}
      // exit={true}
    >
      <div className="show-results" ref={resultsRef}>
        <h2 className="h1 mt-45">
          {formatMessage(searchFlightForm.selectYourFlight)}{' '}
          {isLoading && <Loading className="w-20 h-20" viewBox="0 0 40 40" />}
        </h2>
        <ul className="my-25">
          {sortMergedResults(data).map(([id, flight]) => (
            <SingleResult
              ref={resultRef}
              key={`flight-${id}`}
              flight={flight}
              flightIndex={flightIndex}
              flightType={flightType}
              submitForm={submitForm}
              departureDate={values.departure_date}
              step={step}
              flightsCount={size(data)}
            />
          ))}
        </ul>
        {isMobile && size(data) === 1 && (
          <Button
            className="hidden smd:block"
            type="submit"
            onClick={resultRef?.current?.selectFlight}
            theme="primary"
            disabled={!isValid || isSubmitting}
            isSubmitting={isSubmitting}
          >
            {formatMessage(searchFlightForm.selectFlightButton)}
          </Button>
        )}
        <p>
          <span className="link" onClick={flightNotVisibleClick}>
            {formatMessage(searchFlightForm.myFlightIsNotDisplayed)}
          </span>
        </p>
      </div>
    </CSSTransition>
  );
};

export default ResultsList;
