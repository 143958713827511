// React specific
import React from 'react';

// Models
import { FlightsAirportModel } from '../models/FlightsAirportModel';

const Airport = (airport: { airport: FlightsAirportModel }): JSX.Element => {
  return (
    <span key={airport.airport?.id}>
      {airport &&
        `${airport.airport?.city?.name ||
          airport.airport?.name ||
          airport.airport?.label} (${airport.airport?.iata ||
          airport.airport?.icao})`}
    </span>
  );
};

export default Airport;
