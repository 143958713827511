export default {
  primary05: 'rgba(250, 192, 21, 0.05)',
  primary25: '#FFFCF4',
  primary50: '#F4FAF8',
  primary100: '#fff8e6',
  primary200: '#eed16e',
  primary300: '#facd42',
  primary400: '#139bf1',
  primary500: '#FFE187',
  primaryButtonText: '#000000',
  primaryButtonTextInverted: '#139bf1',
  primaryCalendarIconFilter:
    'sepia(11%) saturate(5499%) hue-rotate(171deg) brightness(95%)',
  primaryShadowTilePrimary: '0 1px 0 2px rgba(243, 100, 57, 1)',
  secondary05: 'rgba(250, 192, 21, 0.05)',
  secondary25: '#f36439',
  secondary50: '#F4FAF8',
  secondary100: '#fff8e6',
  secondary200: '#fbd202',
  secondary300: '#139bf1',
  secondary400: '#139bf1',
  secondary500: '#f36439',
  secondaryButtonText: '#000000',
  secondaryButtonTextInverted: '#139bf1',
  h1: '#139bf1',
  link: '#000000',
};
