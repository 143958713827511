export const hotjarIdMap = {
  'vol-retarde-fr': 652395,
  'flight-delayed-co-uk': 652355,
  'flug-verspaetet-de': 652354,
  'vlucht-vertraagd-nl': 650418,
};

export function hotjar(calculatorDomain) {
  return `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${parseInt(
    hotjarIdMap[calculatorDomain]
  )},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
}

export function getLocale(config) {
  if (config?.locale && config?.country) {
    return `${config.locale}-${config.country.toUpperCase()}`;
  }
  return `en-US`;
}

export function dataLayer(config) {
  return `window.dataLayer = window.dataLayer || [];window.dataLayer.push({'country':'${getLocale(
    config
  )}'});`;
}

export const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-P5LQ4SR');(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-WBVHR929');`;
