import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// Components
import InputGroupWrapper from './InputGroup';
import translatable from '../../utils/propTypes/translatable';

const NativeSelect = ({
  name,
  className,
  placeholder,
  options,
  onChange,
  onChangeCallback,
  disabled,
  ...props
}) => {
  const intl = useIntl();
  const placeholderText = placeholder?.id
    ? intl.formatMessage(placeholder)
    : placeholder;

  function onValueChange(e) {
    onChange && onChange(e?.target?.value);
    props.field.onChange(e);
    onChangeCallback && onChangeCallback(e?.target?.value);
  }

  return (
    <select
      name={name}
      placeholder={placeholderText}
      className={clsx(
        'form-select',
        'w-full',
        'sm:w-315',
        {
          'form-input-error': props.hasError,
          'text-gray-300': !props?.field?.value,
          'bg-gray-100 text-gray-300 border hover:border-gray-200 cursor-not-allowed': disabled,
        },
        className
      )}
      aria-invalid={props.hasError}
      {...props.field}
      onChange={onValueChange}
      disabled={disabled}
    >
      <option value="" disabled selected={props?.selectedPlaceholder}>
        {placeholderText}
      </option>
      {options.map((option, index) =>
        !option.parent ? (
          // eslint-disable-next-line
          <option key={`name-${index}`} value={option.value}>
            {option.label?.id ? intl.formatMessage(option.label) : option.label}
          </option>
        ) : (
          // eslint-disable-next-line
          <optgroup key={`nameGroup-${index}`} label={option.parent.label}>
            {/* tslint:disable-next-line:no-shadowed-variable */}
            {option.children.map(({ label, value }, index) => (
              // eslint-disable-next-line
              <option key={`name-${index}`} value={value}>
                {label?.id ? intl.formatMessage(label) : label}
              </option>
            ))}
          </optgroup>
        )
      )}
    </select>
  );
};

NativeSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  isTouched: PropTypes.bool,
  label: translatable,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeCallback: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: translatable,
};

// eslint-disable-next-line react/no-multi-comp
const wrapped = props => (
  <InputGroupWrapper {...props} component={NativeSelect} />
);

wrapped.propTypes = {
  label: translatable,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: translatable,
};

export default wrapped;
