import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const StepTitle = ({ style, children, className }) => (
  <h1
    style={style}
    className={clsx(
      'mb-30 sm:my-30 sm:mt-0 mx-10 sm:mx-20 lg:mx-0 text-center sm:text-left',
      className
    )}
  >
    {children}
  </h1>
);

StepTitle.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default StepTitle;
