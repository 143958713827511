// React specific
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FlightAirportInformationStripe from '../FlightAirportInformationStripe';
import Card from '../../components/Card';
import DelayTile from './DelayTile';
import NativeSelect from '../../components/fields/NativeSelect';

// Models & Types
import { CustomerFlightModel } from '../../models/CustomerFlightModel';
import { FlightDisruption } from '../FlightSearch';

// Third party, Services, State
import build from 'redux-object';
import {
  useGlobalApi,
  useGlobalState,
} from '../../services/GlobalStore/GlobalStore';

// Translations
import flightDisruptionMessages from '../../messages/flightDisruptionMessages';

// For each disruption please add corresponding translation key in /messages/flightDisruptionMessages
export const flightDisruptionOptions = (flightIndex = 0) => {
  const options = [
    FlightDisruption.Delayed,
    FlightDisruption.Canceled,
    FlightDisruption.Rebooked,
    FlightDisruption.Diverted,
    FlightDisruption.Denied,
    FlightDisruption.OnTime,
  ];

  if (flightIndex > 0) {
    options.unshift(FlightDisruption.MissedConnection);
  }

  return options.map(disruption => ({
    value: disruption,
    label: flightDisruptionMessages[disruption],
  }));
};

const EnterFlightDisruption = ({ flightIndex, flight }: any) => {
  const [customerFlights] = useGlobalState('customerFlights.booked');

  const [api] = useGlobalApi();
  const [airports] = useGlobalState('airports');
  const departureAirportData =
    airports?.[customerFlights[flightIndex]?.departure_airport_id] ||
    build(api, 'airports', customerFlights[flightIndex].departure_airport_id);
  const arrivalAirportData =
    airports?.[customerFlights[flightIndex]?.arrival_airport_id] ||
    build(api, 'airports', customerFlights[flightIndex].arrival_airport_id);

  // Create a model for the FlightAirportInformationStripe
  const flights: CustomerFlightModel[] = [
    {
      departureAirport: departureAirportData,
      arrivalAirport: arrivalAirportData,
    },
  ];

  return (
    <Card
      onlyMobile={true}
      className="sm:mt-50 first:mt-0 mb-20 relative last:mb-0"
    >
      <FlightAirportInformationStripe
        flights={flights}
        borderBottom={true}
        funnelFlight={flight}
      />
      <div className="sm:flex mt-20">
        <NativeSelect
          name={`flightDisruptions.${flightIndex}`}
          options={flightDisruptionOptions(flightIndex)}
          label={flightDisruptionMessages.label}
          placeholder={flightDisruptionMessages.placeholder}
        />
        <DelayTile flight={flight} />
      </div>
    </Card>
  );
};

EnterFlightDisruption.propTypes = {
  flight: PropTypes.object,
  flightIndex: PropTypes.number,
};

export default EnterFlightDisruption;
