export default {
  primary05: 'rgba(250, 192, 21, 0.05)',
  primary25: '#FFFCF4',
  primary50: '#F4FAF8',
  primary100: '#fff8e6',
  primary200: '#eed16e',
  primary300: '#facd42',
  primary400: '#fcc002',
  primaryButtonText: '#000000',
  primaryButtonTextInverted: '#fcc002',
  primaryCalendarIconFilter:
    'sepia(1) saturate(6) brightness(2) hue-rotate(320deg)',
  primaryShadowTilePrimary: '0 1px 0 2px rgba(255, 23, 68, 1)',
  secondary05: 'rgba(250, 192, 21, 0.05)',
  secondary25: '#ff1744',
  secondary50: '#F4FAF8',
  secondary100: '#fff8e6',
  secondary200: '#fbd202',
  secondary300: '#fcc002',
  secondary400: '#fcc002',
  secondary500: '#ff1744',
  secondaryButtonText: '#000000',
  secondaryButtonTextInverted: '#fcc002',
  h1: '#fcc002',
  link: '#000000',
};
