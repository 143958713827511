import { useEffect, useState } from 'react';
import { parse } from 'qs';
import flightTixLogo from '../assets/images/flight-tix-logo.svg';
import gate1Logo from '../assets/images/gate1-logo.png';
import tixLogo from '../assets/images/tix.svg';
import vliegNlLogo from '../assets/images/vliegtickets-logo-nl.svg';
import vliegBeLogo from '../assets/images/vliegtickets-logo-be.svg';
import wtcLogo from '../assets/images/WTC.nl.svg';
import config from '../config.json';

/**
 * Setup defined based on url queryParams on CoBranding pages
 */
interface CoBrandingLocalSetup {
  /**
   * Translations messages object specified by `lang` queryParam
   */
  translations: { [key: string]: string } | null;
  /**
   * URL of logo to show specified by `partner` queryParam
   */
  logoUrl: string;
  /**
   * URL of API specified by `partner` queryParam
   */
  apiUrl: string;
  dynamicConfig: DynamicConfig | null;
}

/**
 * Describes keys available in config
 * used for cobranding to dynamically set urls
 */
interface DynamicConfig {
  site: string;
  siteLogo: string;
  siteName: string;
  apiUrl: string;
  portalUrl: string;
  calculatorDomain: string;
  locale: string;
  country: string;
  twitterHandle: string;
  facebookLink: string;
  metaImage: string;
  portalLoginUrl: string;
  passwordChangeUrl: string;
  termsOfServiceUrl: string;
  pricingListUrl: string;
  howItWorksUrl: string;
  serviceUrl: string;
  faqUrl: string;
  testimonialsUrl: string;
  contactUrl: string;
  blankPowerOfAttorneyUrl: string;
  blankPartnerPowerOfAttorneyUrl: string;
}

/**
 *  Find logo based on partner param
 *  Be careful as it uses partial matching
 */
const partnerToLogoMap = (partner: string) => {
  if (partner.includes('flighttix')) return flightTixLogo;
  if (partner.includes('tix')) return tixLogo;
  if (partner.includes('gate1')) return gate1Logo;
  if (partner.includes('vliegtickets-nl')) return vliegNlLogo;
  if (partner.includes('vliegtickets-be')) return vliegBeLogo;
  if (partner.includes('wtc')) return wtcLogo;
};

export const useCoBrandingParams = (): CoBrandingLocalSetup => {
  const [translations, setTranslations] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [apiUrl, setApiUrl] = useState(config.apiUrl);
  const [dynamicConfig, setDynamicConfig] = useState<DynamicConfig | null>(
    config
  );

  useEffect(() => {
    const languageParam = parse(location?.search.slice(1))?.lang;
    const partnerParam = parse(location?.search.slice(1))?.partner;

    if (location.pathname === '/' && !languageParam && !partnerParam) {
      sessionStorage.removeItem('lang');
      sessionStorage.removeItem('partner');
    }

    if (languageParam) sessionStorage.setItem('lang', languageParam);
    if (languageParam || sessionStorage.getItem('lang')) {
      const currentLang = languageParam || sessionStorage.getItem('lang');
      import(
        `../translations/${currentLang}${currentLang !== 'keys' ? '.json' : ''}`
      )
        .then(data => {
          setTranslations(data?.default);
        })
        .catch(() => {
          setTranslations(null);
        });
    }

    if (partnerParam) sessionStorage.setItem('partner', partnerParam);
    if (partnerParam || sessionStorage.getItem('partner')) {
      const currentPartner = partnerParam || sessionStorage.getItem('partner');
      import(
        `../${currentPartner ? `configs/${currentPartner}` : 'config'}.json`
      )
        .then(data => {
          setApiUrl(data?.default.apiUrl);
          setDynamicConfig(data?.default);
        })
        .catch(() => {
          setApiUrl(config.apiUrl);
        });
      setLogoUrl(
        partnerToLogoMap(partnerParam || sessionStorage.getItem('partner'))
      );
    }
  }, []);

  return { translations, logoUrl, apiUrl, dynamicConfig };
};
