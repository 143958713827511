import '../utils/yupIntlSetup';
import { add } from 'date-fns';
import format from 'date-fns/format';
import * as yup from 'yup';
import { parseISO, isValid } from 'date-fns';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import validationMessages from '../views/messages/validationMessages';
import translations from '../translations/main.json';

export const validateDate = yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
  message: validationMessages.invalidDate,
});

const maxFlightDate = format(
  add(new Date(), {
    years: 1,
  }),
  'yyyy-MM-dd'
);

export const validateFlightDate = yup
  .date()
  .max(maxFlightDate, translations?.['cc4.form.search-flights.date.max']);

export const validateFlightCode = yup
  .string()
  .matches(
    /^([A-Za-z]{2,3}|[A-Za-z](\s+)?\d|\d[A-Za-z])(\s+)?(\d{1,4})(\D)?$/,
    {
      message: validationMessages.invalidFlightCode,
      excludeEmptyString: true,
    }
  );

export const validateBookingReference = yup
  .string()
  .matches(/^([A-Za-z0-9])+$/, {
    message: validationMessages.onlyAlnum,
    excludeEmptyString: true,
  });

/**
 * Validate a birthday format
 */
export const validateBirthday = yup
  .string()
  .test('is-valid-birthday', validationMessages.invalidDate, value => {
    const today = new Date();
    const parsedValue = parseISO(value);
    // Birthday cannot be in the future or before 01-01-1900
    if (
      isValid(parsedValue) &&
      today.getTime() > parsedValue.getTime() &&
      parsedValue.getFullYear() > 1899
    ) {
      return true;
    } else {
      return false;
    }
  });

export const validatePhoneNumber = yup
  .string()
  .test(
    'is-valid-phone-number',
    validationMessages.invalidPhoneNumber,
    value => {
      const phoneNumber = parsePhoneNumber(value || '');
      if (!value || !phoneNumber?.country) return true;
      return isValidPhoneNumber(phoneNumber?.number) === true;
    }
  );

export const validateFirstname = yup
  .string()
  .min(1)
  .required();

export const validateLastname = yup
  .string()
  .min(1)
  .required();

const FILE_SIZE = 160 * 1024;

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/rtf',
  'text/plain',
  'image/bmp',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const validateFileSize = {
  name: 'fileSize',
  test: value => {
    return value && value.size <= FILE_SIZE;
  },
  message: validationMessages.fileSize,
};

export const validateFileFormat = {
  name: 'fileFormat',
  test: value => {
    return value && SUPPORTED_FORMATS.includes(value.type);
  },
  message: validationMessages.fileFormat,
};
