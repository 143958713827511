import React from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import ReactSelectWrapper from '../ReactSelectWrapper';
import translatable from '../../../utils/propTypes/translatable';

export const airportSelectMessages = defineMessages({
  labelDepartureAirport: {
    id: 'cc4.form.field.label.airport.departure',
    defaultMessage: 'Departure',
  },
  labelStopoverAirport: {
    id: 'cc4.form.field.label.airport.stopover',
    defaultMessage: 'Stop {number}',
  },
  labelArrivalAirport: {
    id: 'cc4.form.field.label.airport.final-destination',
    defaultMessage: 'Final destination',
  },
  placeholderDepartureAirport: {
    id: 'cc4.form.field.placeholder.airport.departure',
    defaultMessage: 'e.g. Amsterdam or AMS',
  },
  placeholderStopoverAirport: {
    id: 'cc4.form.field.placeholder.airport.stopover',
    defaultMessage: 'e.g. Warsaw or WAW',
  },
  placeholderArrivalAirport: {
    id: 'cc4.form.field.placeholder.airport.arrival',
    defaultMessage: 'e.g. Barcelona or BCN',
  },
  addStopover: {
    id: 'cc4.form.search-flights.add-stopover-text',
    defaultMessage: 'Add another stop',
  },
  removeStopover: {
    id: 'cc4.form.search-flights.remove-stopover-text',
    defaultMessage: 'Remove stopover',
  },
});

const buildAirportValue = airport => ({
  value: airport.id,
  label: `${airport.name}${
    airport.city?.name ? ` (${airport.city.name})` : ''
  }`,
  leftLabel: airport.iata || airport.icao,
  ...airport,
});

const AirportSelect = ({ ...props }) => (
  <ReactSelectWrapper
    {...props}
    additionalQueryParams={{ sort: '-priority', include: 'city' }}
    endpoint="airports"
    searchOnFilters={['search']}
    valueConstructor={buildAirportValue}
  />
);

AirportSelect.defaultProps = {
  isClearable: true,
  placeholder: airportSelectMessages.placeholderAirport,
};

AirportSelect.propTypes = {
  isClearable: PropTypes.bool,
  label: translatable,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.object,
};

export default AirportSelect;
