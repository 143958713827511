import React, { useState, useEffect } from 'react';
// tslint:disable:no-submodule-imports
import isEmpty from 'lodash/isEmpty';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import { clearPart1Data } from '../../services/resources/claims';
// Backend services
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
// Hooks
import useAuthenticate from '../../hooks/useAuthenticate';
// Models
import { CustomerFlight } from '../models/CustomerFlightModel';
import { ClaimModel } from '../models/ClaimModel';
// Translations
import messages from './messages';
// tslint:enable:no-submodule-imports

const DuplicateClaimAlert = ({
  formatMessage,
  customerFlights,
  navigate,
}: {
  formatMessage: ({}) => React.ReactNode;
  customerFlights: CustomerFlight[];
  navigate: (arg0: string) => void;
}) => {
  const { isLoggedIn } = useAuthenticate();
  const [showAlert, setShowAlert] = useState(true);

  const { data: claims, get: getClaims } = useJsonApi(`/claims`, {
    cachePolicy: 'no-cache',
    queryParams: {
      include: [
        'customer',
        'currentState',
        'placeOfJurisdiction',
        'customerFlights.airline',
        'customerFlights.departureAirport',
        'customerFlights.departureAirport.city',
        'customerFlights.arrivalAirport',
        'customerFlights.arrivalAirport.city',
        'flights.airline',
        'flights.departureAirport',
        'flights.departureAirport.city',
        'flights.arrivalAirport',
        'flights.arrivalAirport.city',
      ].join(),
    },
  });

  useEffect(() => {
    if (!claims && isLoggedIn) getClaims();
  }, [claims, getClaims, isLoggedIn]);

  function hideAlert() {
    setShowAlert(false);
  }

  function clearLocalStorageAndNavigate() {
    clearPart1Data();
    navigate('/');
  }

  const bookedFlights = claims?.map((claim: ClaimModel) =>
    claim.flights.find(flight => flight.meta.pivot.type === 'booked')
  );

  const matchedFlights = bookedFlights?.filter(bookedFlight =>
    customerFlights.some(
      customerFlight =>
        customerFlight?.airline_code === bookedFlight?.airline_code &&
        customerFlight?.flight_number === bookedFlight?.flight_number &&
        customerFlight?.scheduled_gate_departure_date ===
          bookedFlight?.scheduled_gate_departure_date
    )
  );

  return (
    <>
      {showAlert && !isEmpty(matchedFlights) ? (
        <Alert level="warning" className="mb-40 flex-col">
          <div className="ml-60 -mt-25">
            <span className="text-lg text-gray-800 font-bold">
              {formatMessage(messages.checkCompensationWarning)}
            </span>
            <div className="flex pt-15">
              <Button theme="secondary" size="small" onClick={hideAlert}>
                {formatMessage(messages.checkCompensationModalConfirm)}
              </Button>
              <Button
                theme="info"
                size="small"
                onClick={clearLocalStorageAndNavigate}
              >
                {formatMessage(messages.checkCompensationModalCancel)}
              </Button>
            </div>
          </div>
        </Alert>
      ) : null}
    </>
  );
};

export default DuplicateClaimAlert;
