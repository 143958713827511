import { defineMessages } from 'react-intl';

const commonMessages = defineMessages({
  add: {
    id: 'cc4.common.button.add',
    defaultMessage: 'Add',
  },
  remove: {
    id: 'cc4.common.button.remove',
    defaultMessage: 'Remove',
  },
  edit: {
    id: 'cc4.common.button.edit',
    defaultMessage: 'Edit',
  },
  showMore: {
    id: 'cc4.common.show-more',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'cc4.common.show-less',
    defaultMessage: 'Show less',
  },
  back: {
    id: 'cc4.common.button.back',
    defaultMessage: 'Back',
  },
  clear: {
    id: 'cc4.common.button.clear',
    defaultMessage: 'Clear',
  },
  yes: {
    id: 'cc4.common.button.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'cc4.common.button.no',
    defaultMessage: 'No',
  },
  seoDefaultTitle: {
    id: 'cc4.seo.general.default-title',
    defaultMessage: 'Flight delayed.com',
  },
  seoDefaultCobrandingTitle: {
    id: 'cc4.seo.general.default-cobranding-title',
    defaultMessage: '',
  },
  seoTitleTemplate: {
    id: 'cc4.seo.general.title-template',
    defaultMessage: '{siteName} - %s',
  },
  seoDescription: {
    id: 'cc4.seo.general.description',
    defaultMessage: 'Flight delayed.com',
  },
  seoCobrandingDescription: {
    id: 'cc4.seo.general.cobranding-description',
    defaultMessage: '',
  },
  seoKeywords: {
    id: 'cc4.seo.general.keywords',
    defaultMessage: 'delayed flight, cancelled flight',
  },
  seo404Title: {
    id: 'cc4.seo.general.404-title',
    defaultMessage: '404: Not found',
  },
  seo500Title: {
    id: 'cc4.seo.general.500-title',
    defaultMessage: '500: Fatal error',
  },
  createOption: {
    id: 'cc4.form.field.general.create-option',
    defaultMessage: 'Add {value}',
  },
  errorPageTitle: {
    id: 'cc4.error-page.title-header',
    defaultMessage: 'Sorry',
  },
  errorDescription404: {
    id: 'cc4.error-page.error-description-404',
    defaultMessage: 'page not found',
  },
  errorDescription500: {
    id: 'cc4.error-page.error-description-500',
    defaultMessage: 'error occurred',
  },
  backToHome: {
    id: 'cc4.error-page.back-to-home-button-text',
    defaultMessage: 'Back to homepage',
  },
  stepFlightInfo: {
    id: 'cc4.common.progress-stepper.flight-info',
    defaultMessage: 'Flight info',
  },
  stepPersonalInfo: {
    id: 'cc4.common.progress-stepper.personal-info',
    defaultMessage: 'Your details',
  },
  stepAuthorisation: {
    id: 'cc4.common.progress-stepper.authorisation',
    defaultMessage: 'Authorisation',
  },
  stepFinish: {
    id: 'cc4.common.progress-stepper.finish',
    defaultMessage: 'Finish',
  },
  partOfYource: {
    id: 'cc4.common.logo.part-of-yource',
    defaultMessage: 'part of',
  },
  poweredByYource: {
    id: 'cc4.common.logo.powered-by-yource',
    defaultMessage: '<strong>Claim service</strong> powered by',
  },
  loginText: {
    id: 'cc4.common.login',
    defaultMessage: 'Login',
  },
  logoutText: {
    id: 'cc4.common.logout',
    defaultMessage: 'Logout',
  },
  myClaims: {
    id: 'cc4.common.my-claims',
    defaultMessage: 'My claims',
  },
  myClaim: {
    id: 'cc4.common.my-claim',
    defaultMessage: 'My claim',
  },
  support: {
    id: 'cc4.common.support',
    defaultMessage: 'Support',
  },
  newClaim: {
    id: 'cc4.common.new-claim',
    defaultMessage: 'New claim',
  },
  phoneInputCountryPlaceholder: {
    id: 'cc4.form.passengers.phone-input-country-placeholder',
    defaultMessage: 'Search...',
  },
  socialProof: {
    id: 'cc4.common.social-proof',
    defaultMessage: 'We were featured in',
  },
});

export default commonMessages;
