import React from 'react';
import clsx from 'clsx';
import Card from './Card';
import TrustMini from './TrustMini';

const Aside = ({
  className,
  children,
  showSocialProof,
}: {
  className?: string;
  children: React.ReactNode;
  showSocialProof?: boolean;
}): JSX.Element => (
  <aside className="w-aside ml-30">
    <Card
      className={clsx('hidden lg:block flex-shrink-0 mb-30', className)}
      aside={true}
    >
      {showSocialProof ? <TrustMini /> : null}
      {children}
    </Card>
    {!showSocialProof ? <TrustMini /> : null}
  </aside>
);

export default Aside;
