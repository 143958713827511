import { useCallback, useEffect, useMemo, useState } from 'react';
import hmacSha256 from 'crypto-js/hmac-sha256';
import { pick } from 'ramda';
import useFetch from 'use-http';
import moment from 'moment';
import {
  useGlobalState,
  useJsonApi,
} from '../../services/GlobalStore/GlobalStore';
import { useFlightDataFetch } from './useFlightDataFetch';
import { removeCustomerFlights } from '../../services/GlobalStore/reducer';
import { stringifyJsonApiParams } from '../../services/api';

/**
 * Transforms two response shapes to one flight object
 */
const transformFlight = flight => {
  let data = pick(
    [
      'flight_number',
      'airline_code',
      'scheduled_gate_departure_date',
      'scheduled_gate_departure_time',
      'scheduled_runway_departure_time',
      'scheduled_gate_arrival_time',
      'scheduled_runway_arrival_time',
      'departure_time',
      'arrival_time',
      'first_occurrence',
      'last_occurrence',
      'status',
      'time_delayed',
      'priority',
    ],
    flight?.attributes ? flight.attributes : flight
  );

  let departure_airport = flight?.attributes
    ? flight.attributes.departure_airport
    : flight.departureAirport.iata;
  let arrival_airport = flight?.attributes
    ? flight.attributes.arrival_airport
    : flight.arrivalAirport.iata;

  let departure_time =
    data.departure_time ||
    data.scheduled_gate_departure_time ||
    data.scheduled_runway_departure_time;
  let arrival_time =
    data.arrival_time ||
    data.scheduled_gate_arrival_time ||
    data.scheduled_runway_arrival_time;

  return pick(
    [
      'flight_number',
      'airline_code',
      'scheduled_gate_departure_date',
      'departure_time',
      'arrival_time',
      'first_occurrence',
      'last_occurrence',
      'arrival_airport',
      'departure_airport',
      'status',
      'time_delayed',
      'priority',
    ],
    {
      ...data,
      departure_time: departure_time?.substr(0, 5) ?? '',
      arrival_time: arrival_time?.substr(0, 5) ?? '',
      departure_airport,
      arrival_airport,
    }
  );
};
/**
 *
 * @param response
 * @param departureDate
 * @returns {function(*) : *}
 */

export function hydrateResults(response, departureDate) {
  return prevState => {
    let flights = {};

    /* Normalized data is one level higher */
    let results = response?.data?.data || response?.data;

    response &&
      !response.loading &&
      results?.length &&
      results?.forEach(flight => {
        let transformedFlight = transformFlight(flight);
        let {
          flight_number,
          airline_code,
          scheduled_gate_departure_date,
          first_occurrence, // no specific dates in flightSources - this is a replacement
          last_occurrence, // no specific dates in flightSources - this is a replacement
          arrival_airport,
          departure_airport,
        } = transformedFlight;

        let key = `${scheduled_gate_departure_date ||
          departureDate ||
          ''}${airline_code}${flight_number}${departure_airport}${arrival_airport}`;
        // Uniqueness of flight is based on data, not uid from backend, that's why we need to do this
        // Also we don't add a flight from flightSources if user specified date is out of a range (future date is ok though)
        if (
          dataShouldOverride(prevState[key], transformedFlight) &&
          flightOccursInThisDate(
            departureDate,
            first_occurrence,
            last_occurrence
          )
        ) {
          Object.assign(flights, {
            [key]: transformedFlight,
          });
        }
      });

    return {
      ...prevState,
      ...flights,
    };
  };
}

/**
 * Override policy for flights merge (notice that occurrence of checks matters)
 * @param previous
 * @param next
 * @returns {boolean}
 */
const dataShouldOverride = (previous, next) => {
  if (!previous) return true;
  const nextFlightHasHigherPriority =
    !next?.priority && !previous?.priority
      ? false
      : next?.priority && !previous?.priority
      ? true
      : next?.priority &&
        previous?.priority &&
        next?.priority > previous?.priority;
  if (nextFlightHasHigherPriority) return true;
  else {
    if (!previous?.status && next?.status) return true;
    if (!previous?.time_delayed && next?.time_delayed) {
      return true;
    }
  }
  return false;
};

const flightOccursInThisDate = (
  departureDate,
  firstOccurrence,
  lastOccurrence
) => {
  if (!departureDate) return true;
  if (!firstOccurrence && !lastOccurrence) return true;
  if (departureDate && !lastOccurrence && firstOccurrence) {
    return departureDate >= firstOccurrence;
  }
  if (departureDate && !firstOccurrence && lastOccurrence) {
    return departureDate <= lastOccurrence;
  }
  return departureDate >= firstOccurrence && departureDate <= lastOccurrence;
};

export default function({
  values,
  isFlightCodeShown,
  flightIndex,
  flightType,
}) {
  const [airlines] = useGlobalState('airlines');
  const [airports] = useGlobalState('airports');
  const [data, setData] = useState({});
  const [, dispatch] = useGlobalState();

  const {
    departure_date,
    flight_code,
    airline,
    arrival_airport,
    departure_airport,
  } = values;

  // String that is used for useFetch dependency
  const flightDetails =
    (flight_code || airline + arrival_airport + departure_airport) +
    departure_date;

  // Reinitialize filters whenever form values change
  const filter = useMemo(
    () => ({
      departure_date,
      ...(isFlightCodeShown ? { flight_code } : {}),
      ...(!isFlightCodeShown
        ? {
            airline_code: airlines?.[airline]?.corporation
              ? airlines?.[airline]?.corporation
              : [airlines?.[airline]?.iata],
          }
        : {}),
      ...(!isFlightCodeShown
        ? { arrival_airport: airports?.[arrival_airport]?.iata }
        : {}),
      ...(!isFlightCodeShown
        ? { departure_airport: airports?.[departure_airport]?.iata }
        : {}),
    }),
    // eslint-disable-next-line
    [flightDetails]
  );

  const filtersString = useMemo(
    () => stringifyJsonApiParams({ filter }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flightDetails]
  );

  /**
   *  Remove previous results and flight whenever form values change
   */
  useEffect(
    () => {
      setData({});
      dispatch(removeCustomerFlights(flightIndex, flightType));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flightDetails]
  );

  // Request new data whenever forms values change
  const internalFlights = useJsonApi(
    `/flights?${filtersString}&include=departureAirport,arrivalAirport`,
    {
      cachePolicy: 'no-cache',
    }[flightDetails]
  );
  const externalFlights = useFetch(
    'https://flights.your-ce.com/api/3/flightSources?' + filtersString,
    [flightDetails]
  );
  const hydratedExternalFlights = useFetch(
    'https://flights.your-ce.com/api/3/flightSources?' +
      filtersString +
      '&hydrate=' +
      hmacSha256(
        encodeURI(
          'https://flights.your-ce.com/api/3/flightSources?' + filtersString
        ),
        process.env.HYDRATION_KEY
      ),
    [flightDetails]
  );

  const {
    data: flightRoutes,
    get: getFlightRoutes,
    loading: flightRoutesLoading,
  } = useFetch('https://flights.your-ce.com/api/3/flightRoutes', {
    cachePolicy: 'no-cache',
  });

  // Build new results state from responses
  useEffect(() => {
    setData(hydrateResults(externalFlights, departure_date));
    // eslint-disable-next-line
  }, [externalFlights?.loading]);
  useEffect(() => {
    setData(hydrateResults(hydratedExternalFlights, departure_date));
    // eslint-disable-next-line
  }, [hydratedExternalFlights?.loading]);
  useEffect(() => {
    setData(hydrateResults(internalFlights, departure_date));
    // eslint-disable-next-line
  }, [internalFlights?.loading]);

  useEffect(() => {
    setData(
      hydrateResults(
        { data: flightRoutes, loading: flightRoutesLoading },
        departure_date
      )
    );
    // eslint-disable-next-line
  }, [flightRoutesLoading]);

  function fetchFlightRoutes() {
    getFlightRoutes(
      '?' +
        (isFlightCodeShown
          ? stringifyJsonApiParams({
              filter: {
                flight_code: filter.flight_code,
              },
            })
          : stringifyJsonApiParams({
              filter: {
                airline_code: {
                  in: filter.airline_code,
                },
                arrival_airport: filter.arrival_airport,
                departure_airport: filter.departure_airport,
              },
            }))
    );
  }

  // Fetch flights and flightSources +/- 1 day when no results
  let prevDayFilters = stringifyJsonApiParams({
    filter: {
      ...filter,
      departure_date: moment(departure_date, 'YYYY-MM-DD')
        .subtract(1, 'day')
        .format('YYYY-MM-DD'),
    },
  });
  let nextDayFilters = stringifyJsonApiParams({
    filter: {
      ...filter,
      departure_date: moment(departure_date, 'YYYY-MM-DD')
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    },
  });
  const externalFlightsOneDayEarlier = useFetch(
    'https://flights.your-ce.com/api/3/flightSources?' + prevDayFilters
  );
  const externalFlightsOneDayLater = useFetch(
    'https://flights.your-ce.com/api/3/flightSources?' + nextDayFilters
  );
  useEffect(() => {
    setData(hydrateResults(externalFlightsOneDayEarlier, departure_date));
    // eslint-disable-next-line
  }, [externalFlightsOneDayEarlier?.loading]);
  useEffect(() => {
    setData(hydrateResults(externalFlightsOneDayLater, departure_date));
    // eslint-disable-next-line
  }, [externalFlightsOneDayLater?.loading]);

  // Fetch internal flights
  useEffect(() => {
    internalFlights.get();
    // eslint-disable-next-line
  }, [flightDetails]);

  // Fetch flightSources +/- 1 day when no results from external and internal flights
  useEffect(() => {
    if (
      !externalFlights?.loading &&
      !internalFlights?.loading &&
      !hydratedExternalFlights?.loading &&
      externalFlights?.data?.data?.length === 0 &&
      internalFlights?.data?.length === 0 &&
      hydratedExternalFlights?.data?.data?.length === 0
    ) {
      externalFlightsOneDayEarlier.get();
      externalFlightsOneDayLater.get();
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    externalFlights?.loading,
    // eslint-disable-next-line
    internalFlights?.loading,
    // eslint-disable-next-line
    hydratedExternalFlights?.loading,
  ]);

  // Fetch flightRoutes when no results from +/- 1 day
  useEffect(() => {
    if (
      !externalFlightsOneDayEarlier?.loading &&
      !externalFlightsOneDayLater?.loading &&
      externalFlightsOneDayEarlier?.data?.data?.length === 0 &&
      externalFlightsOneDayLater?.data?.data?.length === 0
    ) {
      fetchFlightRoutes();
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    externalFlightsOneDayEarlier?.loading,
    // eslint-disable-next-line
    externalFlightsOneDayLater?.loading,
  ]);

  // Show loading state if anything is fetching
  const isLoading =
    externalFlights?.loading ||
    internalFlights?.loading ||
    hydratedExternalFlights?.loading ||
    externalFlightsOneDayEarlier?.loading ||
    externalFlightsOneDayLater?.loading ||
    flightRoutesLoading;

  const dispatchRemoveFlight = useCallback(
    // tslint:disable-next-line:no-shadowed-variable
    flightIndex => {
      dispatch(removeCustomerFlights(flightIndex, flightType));
    },
    [dispatch, flightType]
  );

  useFlightDataFetch(
    () => {
      dispatchRemoveFlight(flightIndex);
    },
    data,
    departure_date
  );

  return { data, isLoading };
}
