import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import Icon from './Icon';
import closeIcon from '../assets/icons/close-icon.svg';
import infoIcon from '../assets/icons/info-icon-grey.svg';

export interface TranslationType {
  id: string;
  defaultMessage: string;
}

const InfoOverlay = ({
  title,
  description,
}: {
  title: TranslationType;
  description: TranslationType;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Icon
        src={infoIcon}
        className="h-30 w-30 mb-30 mx-10 sm:mx-0 visible lg:invisible"
        onClick={handleOpen}
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-50 sm:pl-32">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-12 shadow-xl">
                      <div className="px-16 sm:px-24">
                        <div
                          className={clsx(
                            'flex items-center',
                            title ? 'justify-between' : 'justify-end'
                          )}
                        >
                          {title ? (
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              {formatMessage(title)}
                            </Dialog.Title>
                          ) : null}
                          <div className="ml-12 flex h-25 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <Icon src={closeIcon} className="w-25 h-25" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-12 flex-1 px-20 sm:px-12 whitespace-pre-line">
                        <div className="absolute inset-0 px-20 sm:px-12">
                          {formatMessage(description)}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default InfoOverlay;
