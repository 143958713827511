import { useEffect } from 'react';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import { stringifyJsonApiParams } from '../../services/api';
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';

const sortByAlpha = (a, b) => (a < b ? 1 : a > b ? -1 : 0);

export function useFlightDataFetch(callback, flights, departureDate) {
  const {
    data: airports,
    get: getAirports,
    loading: airportsLoading,
  } = useJsonApi('/airports');
  const {
    data: airlines,
    get: getAirlines,
    loading: airlinesLoading,
  } = useJsonApi('/airlines');

  useEffect(() => {
    if (!flights || !size(flights)) {
      return;
    }

    let toFetch = {
      airports: [],
      airlines: [],
    };

    Object.values(flights).forEach(flight => {
      Object.assign(toFetch, {
        airports: uniq(
          toFetch.airports.concat([
            flight.arrival_airport,
            flight.departure_airport,
          ])
        ),
        airlines: uniq(toFetch.airlines.concat(flight.airline_code)),
      });
    });

    if (toFetch.airports.length) {
      getAirports(
        '?' +
          stringifyJsonApiParams({
            filter: {
              code: toFetch.airports
                .sort(sortByAlpha)
                .join()
                .toLowerCase(),
            },
            include: 'city',
          })
      );
    }

    if (toFetch.airlines.length) {
      getAirlines(
        '?' +
          stringifyJsonApiParams({
            filter: {
              code: toFetch.airlines
                .sort(sortByAlpha)
                .join()
                .toLowerCase(),
              operational_on: departureDate,
            },
          })
      );
    }
    // eslint-disable-next-line
  }, [size(flights)]);

  useEffect(() => {
    if (
      !airlinesLoading &&
      !airportsLoading &&
      airports !== undefined &&
      airlines !== undefined
    ) {
      callback();
    }
    // eslint-disable-next-line
  }, [airportsLoading, airlinesLoading]);
}
