import { themes } from './index';

export interface ITheme {
  [key: string]: string;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string | null;
}

export const mapTheme: (variables: ITheme) => IMappedTheme = (
  variables: ITheme
) => {
  return {
    '--color-primary05': variables.primary05 || '',
    '--color-primary10': variables.primary10 || '',
    '--color-primary25': variables.primary25 || '',
    '--color-primary50': variables.primary50 || '',
    '--color-primary100': variables.primary100 || '',
    '--color-primary200': variables.primary200 || '',
    '--color-primary300': variables.primary300 || '',
    '--color-primary400': variables.primary400 || '',
    '--color-primaryButtonText': variables.primaryButtonText || '',
    '--color-primaryButtonTextInverted':
      variables.primaryButtonTextInverted || '',
    '--color-primaryCalendarIconFilter':
      variables.primaryCalendarIconFilter || '',
    '--color-primaryShadowTilePrimary':
      variables.primaryShadowTilePrimary || '',
    '--color-secondary05': variables.secondary05 || '',
    '--color-secondary100': variables.secondary100 || '',
    '--color-secondary200': variables.secondary200 || '',
    '--color-secondary300': variables.secondary300 || '',
    '--color-secondary400': variables.secondary400 || '',
    '--color-secondary500': variables.secondary500 || '',
    '--color-secondaryButtonText': variables.secondaryButtonText || '',
    '--color-secondaryButtonTextInverted':
      variables.secondaryButtonTextInverted || '',
    '--color-h1': variables.h1 || '',
    [variables.primary400]: variables.primary400 || '',
  };
};

export const extend: (extending: ITheme, newTheme: ITheme) => ITheme = (
  extending: ITheme,
  newTheme: ITheme
): ITheme => {
  return { ...extending, ...newTheme };
};

/**
 * Helper function to set a new theme
 *
 * @param {string} theme The name of the theme to be set
 *
 * @return {void}
 */
export const applyTheme = (theme: string): void => {
  const greenclaim = 'greenclaim';
  const themeObject: IMappedTheme = mapTheme(
    themes[theme] || themes[greenclaim]
  );
  if (!themeObject) return;
  const root = document.documentElement;

  Object.keys(themeObject).forEach(property => {
    if (property === 'name') {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};
