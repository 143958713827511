import PropTypes from 'prop-types';

/**
 * Checks if PropType is string or translatable object
 */
const translatable = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
    description: PropTypes.string,
  }),
]);

export default translatable;
