import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import clsx from 'clsx';

import Label from './Label';
import Error from './Error';
import translatable from '../../utils/propTypes/translatable';

/**
 * Takes formik field meta and returns error or false
 * NB: year validation is done in yupValidators.js so no check needed in this function
 * @returns {*}
 */
export const getFieldErrorMessage = ({ value, error, touched }) => {
  // validation for date fields
  if (
    typeof value === 'string' &&
    (value?.indexOf('/') > -1 || value?.indexOf('-') > -1)
  ) {
    const day = value?.substring(0, value.indexOf('/'));
    const month = value?.split('/')[1];
    const year = value?.substring(0, value.indexOf('-'));

    if (Number(day) > 31 || Number(month) > 12 || year) {
      return error;
    }
    // for all other formik fields:
  } else {
    return value && touched && error;
  }
};

export const InputGroup = ({
  id,
  label,
  children,
  className,
  wrapperClassName,
  meta,
  headerLabel,
  explanation,
  full,
  errorMessage,
  marginLeft,
  marginTop,
  showError,
}) => (
  <div
    className={clsx('input-group', className, wrapperClassName, {
      'sm:max-w-315': !full,
    })}
    style={{ marginLeft: marginLeft ? '36px' : '', marginTop: marginTop || '' }}
  >
    <Label
      id={id}
      label={label}
      asHeader={headerLabel}
      explanation={explanation}
    />
    {children}
    <Error
      errorMessage={getFieldErrorMessage(meta) || errorMessage || showError}
    />
  </div>
);

InputGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  explanation: translatable,
  full: PropTypes.bool,
  headerLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  meta: PropTypes.object,
  showError: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
const InputGroupWrapper = ({ component, ...props }) => {
  const [showError, setShowError] = React.useState('');

  const Component = component;
  const { name, removeValueOnUnmount, formik } = props;

  useEffect(() => {
    return () => {
      if (removeValueOnUnmount && formik) {
        formik.setFieldValue(name, '');
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Field {...props}>
      {fieldProps => (
        <InputGroup
          id={props.id || props.name}
          {...props}
          {...fieldProps}
          showError={showError}
        >
          <Component
            {...props}
            {...fieldProps}
            id={props.id || props.name}
            showError={showError}
            setShowError={setShowError}
            hasError={Boolean(getFieldErrorMessage(fieldProps.meta))}
          />
        </InputGroup>
      )}
    </Field>
  );
};

InputGroupWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
  ]),
  formik: PropTypes.object,
  full: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, translatable]),
  name: PropTypes.string.isRequired,
  removeValueOnUnmount: PropTypes.bool,
  setShowError: PropTypes.string,
  showError: PropTypes.string,
};

export default InputGroupWrapper;
