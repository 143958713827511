export default {
  primary05: 'rgba(250, 192, 21, 0.05)',
  primary25: '#FFFCF4',
  primary50: '#F4FAF8',
  primary100: '#fff8e6',
  primary200: '#eed16e',
  primary300: '#facd42',
  primary400: '#cf0674',
  primary500: '#FFE187',
  primaryButtonText: '#000000',
  primaryButtonTextInverted: '#cf0674',
  primaryCalendarIconFilter:
    'invert(15%) sepia(99%) saturate(7117%) hue-rotate(320deg) brightness(84%) contrast(96%)',
  primaryShadowTilePrimary: '0 1px 0 2px rgba(1, 75, 150, 1)',
  secondary05: 'rgba(250, 192, 21, 0.05)',
  secondary25: '#014b96',
  secondary50: '#F4FAF8',
  secondary100: '#fff8e6',
  secondary200: '#fbd202',
  secondary300: '#cf0674',
  secondary400: '#cf0674',
  secondary500: '#014b96',
  secondaryButtonText: '#000000',
  secondaryButtonTextInverted: '#cf0674',
  h1: '#cf0674',
  link: '#000000',
};
