import React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

// Components
import AirportSelect, {
  airportSelectMessages,
} from '../../components/fields/AirportSelect';
import AirlineSelect from '../../components/fields/AirlineSelect';
import DatePicker from '../../components/fields/DatePicker';
import ResultsList from './ResultsList';
import StepNavigation from '../../components/StepNavigation';

// Icons
import DepartureIcon from '../../assets/icons/departure-icon.inline.svg';
import ArrivalIcon from '../../assets/icons/arrival-icon.inline.svg';

// Utils
import { FlightSearchFormProps } from '.';
import FormikPersist from '../../components/fields/FormikPersist';
import { validateFlightDate } from '../../utils/yupValidators';

// Translations
import messages from '../messages/searchFlightForm';

interface SearchByAirportsProps extends FlightSearchFormProps {
  attemptsCount: number;
  incrementAttempts: () => void;
  showFlightCodeField: () => void;
  isFlightCodeShown: boolean;
  showAirportFields: boolean;
  isFlightItinerary: boolean;
  connectionHasSelected: boolean;
  isFirstPage: boolean;
}

const validationSchema = yup.object().shape({
  departure_airport: yup
    .string()
    .nullable()
    .required(),
  arrival_airport: yup
    .string()
    .nullable()
    .required(),
  airline: yup
    .string()
    .nullable()
    .required(),
  departure_date: validateFlightDate.required(),
});

const SearchByAirports = ({
  showFlightCodeField,
  navigateNext,
  fixedAirports,
  flightIndex,
  navigateBack,
  showAirportFields,
  isFlightItinerary = false,
  connectionHasSelected = false,
  isFirstPage = false,
  ...props
}: SearchByAirportsProps) => {
  const { formatMessage } = useIntl();

  const initialValues =
    fixedAirports?.length === 2
      ? {
          departure_airport: fixedAirports[0],
          arrival_airport: fixedAirports[1],
        }
      : {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={navigateNext}
      isInitialValid={false}
      validationSchema={validationSchema}
      initialErrors={{}}
    >
      {({ isValid, values }) => (
        <Form>
          {fixedAirports?.length === 2 && !showAirportFields ? (
            <>
              <input
                type="hidden"
                name="departure_airport"
                value={fixedAirports[0]}
              />
              <input
                type="hidden"
                name="arrival_airport"
                value={fixedAirports[1]}
              />
            </>
          ) : (
            <div className="fieldset sm:flex-row">
              <AirportSelect
                name="departure_airport"
                label={airportSelectMessages.labelDepartureAirport}
                placeholder={airportSelectMessages.placeholderDepartureAirport}
                icon={<DepartureIcon />}
                autoComplete="off"
                defaultOptions={fixedAirports?.length === 2}
                prefillWithFirstOption={fixedAirports?.length === 2}
              />
              <AirportSelect
                name="arrival_airport"
                label={airportSelectMessages.labelArrivalAirport}
                placeholder={airportSelectMessages.placeholderArrivalAirport}
                icon={<ArrivalIcon />}
                autoComplete="off"
                defaultOptions={fixedAirports?.length === 2}
                prefillWithFirstOption={fixedAirports?.length === 2}
              />
            </div>
          )}
          <div className="fieldset sm:flex-row">
            <AirlineSelect name="airline" autoComplete="off" />
            <DatePicker
              name="departure_date"
              label={messages.flightDateLabel}
              autoComplete="off"
            />
          </div>
          <p>
            {formatMessage(messages.speedUpProcessWithFlightCode, {
              cta: (
                <span className="link" onClick={showFlightCodeField}>
                  {formatMessage(messages.speedUpProcessWithFlightCodeCTA)}
                </span>
              ),
            })}
          </p>
          {isValid &&
          ((connectionHasSelected && isFirstPage) || !isFirstPage) ? (
            <ResultsList
              {...props}
              flightIndex={flightIndex}
              values={values}
              showFlightCodeField={showFlightCodeField}
            />
          ) : isValid && !connectionHasSelected && isFirstPage ? (
            <p style={{ color: 'red' }}>
              {formatMessage(messages.selectConnectionType)}
            </p>
          ) : null}
          <FormikPersist
            localStorageKey={`${props.step}-by-airports`}
            favorInitialValues={fixedAirports?.length === 2}
          />
          <StepNavigation
            onBackClick={navigateBack}
            step={`${props.step}-search-by-airline`}
            portalId="delegateSubmit"
            dataLayerPushOnBack={true}
            disabled={isFlightItinerary}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchByAirports;
