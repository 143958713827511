import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import Tippy from '@tippy.js/react';
import infoIcon from '../../assets/icons/info-icon.svg';
import translatable from '../../utils/propTypes/translatable';

const Label = ({ label, id, asHeader, explanation, tag: Tag }) => {
  const intl = useIntl();

  const translationExists = message => {
    if (!message || !message?.id) {
      return false;
    }
    return message?.id !== intl.formatMessage(message);
  };

  if (label) {
    return (
      <Tag
        className={clsx(
          'mb-10 px-15 lg:px-0 font-pt-sans block text-gray-400 font-bold',
          {
            'h-20 text-base': !asHeader,
            'text-lg leading-tight text-gray-800 mb-15': asHeader,
            'label-description': true,
          }
        )}
        htmlFor={id ?? undefined}
      >
        {typeof label === 'object' && label?.id
          ? intl.formatMessage(label)
          : label}
        {(typeof explanation === 'object' && translationExists(explanation)) ||
        typeof explanation === 'string' ? (
          <Tippy
            content={
              typeof explanation === 'string'
                ? explanation
                : intl.formatMessage(explanation)
            }
            className="tippy"
            placement="top"
            arrow={true}
            maxWidth={280}
          >
            <img
              className="inline ml-10 mb-5"
              src={infoIcon}
              alt={'info icon'}
            />
          </Tippy>
        ) : null}
      </Tag>
    );
  }

  return null;
};

Label.defaultProps = {
  asHeader: false,
  tag: 'label',
};

Label.propTypes = {
  asHeader: PropTypes.bool,
  explanation: translatable,
  id: PropTypes.string,
  label: translatable,
  tag: PropTypes.string,
};

export default Label;
