import { defineMessages } from 'react-intl';

export default defineMessages({
  general: {
    id: 'cc4.error.api.general',
    defaultMessage: 'Sorry! An error occurred.',
  },
  401: {
    id: 'cc4.error.api.401',
    defaultMessage: 'Sorry! You must authorize',
  },
  403: {
    id: 'cc4.error.api.403',
    defaultMessage: 'Sorry! You have no access',
  },
  404: {
    id: 'cc4.error.api.404',
    defaultMessage: "Sorry! This resource doesn't exist or you have no rights",
  },
  419: {
    id: 'cc4.error.api.419',
    defaultMessage:
      'Sorry. Your session timed out. Please refresh page and delete cookies',
  },
  422: {
    id: 'cc4.error.api.422',
    defaultMessage: 'Please check all fields',
  },
  500: {
    id: 'cc4.error.api.500',
    defaultMessage: 'Sorry! A fatal error occurred.',
  },
  errorModalCloseText: {
    id: 'cc4.error.modal.close-text',
    defaultMessage: 'Close',
  },
});
