import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'cc4.form.validation.general.required',
    defaultMessage: 'This field is required',
  },
  invalidFlightCode: {
    id: 'cc4.form.validation.general.invalid-flight-code',
    defaultMessage: 'Invalid flight code',
  },
  onlyAlnum: {
    id: 'cc4.form.validation.general.only-alnum',
    defaultMessage: 'Only letters and digits are allowed',
  },
  invalidDate: {
    id: 'cc4.form.validation.general.invalid-date',
    defaultMessage: 'Invalid date',
  },
  invalidEmail: {
    id: 'cc4.form.validation.general.invalid-email',
    defaultMessage: 'Please enter correct e-mail',
  },
  toShort: {
    id: 'cc4.form.validation.general.to-short',
    defaultMessage: 'Too Short!',
  },
  toLong: {
    id: 'cc4.form.validation.general.to-long',
    defaultMessage: 'Too Long!',
  },
  invalidPhoneNumber: {
    id: 'cc4.form.validation.general.invalid-phone-number',
    defaultMessage: 'Not a valid phone number',
  },
  minAge: {
    id: 'cc4.form.validation.general.min-age',
    defaultMessage: 'Minimum age is 2 years old',
  },
  fileSize: {
    id: 'cc4.form.validation.general.file-size',
    defaultMessage: 'File too large',
  },
  fileFormat: {
    id: 'cc4.form.validation.general.file-format',
    defaultMessage: 'Unsupported file Format',
  },
  fileRequired: {
    id: 'cc4.form.validation.general.file-required',
    defaultMessage: 'A file is required',
  },
  invalidBookingNumber: {
    id: 'cc4.form.validation.general.invalid-booking-number',
    defaultMessage: 'Not a valid booking number/code',
  },
});
