import React from 'react';
import clsx from 'clsx';

const Container = ({
  className,
  children,
  style,
}: {
  className?: string;
  children: any;
  style?: any;
}) => (
  <div
    style={style}
    className={clsx('mt-50 lg:flex items-start mx-auto', className)}
  >
    {children}
  </div>
);

export default Container;
