import React from 'react';
import clsx from 'clsx';

// Icons
import ContinueIcon from '../assets/icons/continue-icon.inline.svg';
import Loading from './Loading';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  className?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
  showIcon?: boolean;
  size?: 'large' | 'medium' | 'small' | 'tiny';
  theme?: 'primary' | 'secondary' | 'link' | 'secondary-fill' | 'info';
  width?: 'full' | 'auto';
  onClick?: () => void;
}

const Button = ({
  children,
  className,
  disabled = false,
  theme,
  size = 'medium',
  showIcon = true,
  width,
  isSubmitting,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={clsx(
        'button',
        {
          [`button-${theme}`]: theme,
          [`button-${size}`]: size,
          [`button-${width}`]: width,
        },
        className
      )}
      disabled={disabled}
      {...props}
    >
      {isSubmitting ? (
        <Loading white={true} />
      ) : (
        <>
          {children}
          {theme === 'primary' && showIcon && (
            <ContinueIcon
              alt="Continue icon"
              className="inline ml-15 fill-button-text-color"
            />
          )}
        </>
      )}
    </button>
  );
};

export default Button;
