import React from 'react';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';
import Icon from './Icon';

// Icon
import errorIcon from '../assets/icons/error-icon.svg';
import warningIcon from '../assets/icons/alert-yellow-icon.svg';
import closeIcon from '../assets/icons/close-icon.svg';
import successIcon from '../assets/icons/check-icon.svg';

const alertStyles = {
  danger: tw`bg-red-200 border-red-400`,
  success: tw`bg-green-100 border-green-400`,
  warning: tw`bg-orange-100 border-orange-300`,
};

const alertIcons = {
  danger: errorIcon,
  success: successIcon,
  warning: warningIcon,
};

const Alert = ({
  children,
  className,
  withClose,
  setCloseIcon,
  iconClassName = '',
  closeIconStyle = {},
}) => {
  const closeAlert = () => {
    if (withClose && setCloseIcon) {
      setCloseIcon(false);
    }
  };

  return (
    <div role="alert" className={className}>
      <>{children}</>
      {withClose && (
        <Icon
          src={closeIcon}
          className={
            iconClassName ||
            'cursor-pointer absolute top-0 bottom-0 right-0 p-15'
          }
          title={`Close icon`}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={closeAlert}
          style={closeIconStyle}
        />
      )}
    </div>
  );
};

Alert.defaultProps = {
  withClose: false,
};

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  className: PropTypes.string,
  closeIconStyle: PropTypes.object,
  iconClassName: PropTypes.string,
  // eslint-disable-next-line
  level: PropTypes.oneOf(Object.keys(alertStyles)),
  setCloseIcon: PropTypes.func,
  withClose: PropTypes.bool,
};

export default styled(Alert)`
  ${tw`flex content-center items-start py-10 bg-none border-l-2 text-black text-base leading-normal`}
  &::before {
    content: '';
    background-image: url(${props => alertIcons[props.level]});
    background-position: top center;
    flex-shrink: 0;
    ${tw`block bg-no-repeat bg-contain w-45 h-20 mt-5 px-25`}
  }
  ${props => alertStyles[props.level]};
  ${props => (props.withClose ? tw`pr-45` : tw`pr-15`)};
`;
