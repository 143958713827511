import { jsonApiAxios } from '../api';

// relation = ward
// type = passenger
const relationshipToTypeMap = {
  wards: 'persons',
  legalGuardians: 'persons',
  countryOfBirth: 'countries',
};

export const personsSchema = {
  attributes: [
    'email',
    'phone',
    'first_name',
    'last_name',
    'date_of_birth',
    'address',
    'city',
    'postal_code',
    'city_of_birth',
    'national_personal_number',
    'national_personal_number_type',
    'passport_document_number',
    'occupation',
    'fiscal_code',
    'has_single_legal_guardian',
    'country',
    'countryOfBirth',
    'legalGuardians',
    'wards',
    'passenger',
    'is_minor',
    'required_fields',
    'missing_fields',
    'required_fields_legal_guardian',
    'signature',
  ],
  country: {
    ref: 'id',
  },
  countryOfBirth: {
    ref: 'id',
  },
  legalGuardians: {
    ref: 'id',
  },
  wards: {
    ref: 'id',
  },
  passenger: {
    ref: 'id',
  },
  typeForAttribute: attribute => relationshipToTypeMap[attribute],
};

/**
 * Saving persons and customer data uses same form but column names are
 * different.
 * `gc_customers.streetname` and `gc_persons.street_name`
 * Fixing this on backend would rise more issues than solutions
 * @param values
 */
const normalizeValues = values => ({
  ...values,
  ...(values.birthday ? { date_of_birth: values.birthday } : {}),
  ...(values.firstname ? { first_name: values.firstname } : {}),
  ...(values.lastname ? { last_name: values.lastname } : {}),
  ...(values.place_of_birth ? { city_of_birth: values.place_of_birth } : {}),
  ...(values.postal ? { postal_code: values.postal } : {}),
  ...(values.streetname ? { address: values.streetname } : {}),
});

export function createPerson(values, config = {}) {
  return jsonApiAxios.post(
    '/persons?include=country,countryOfBirth',
    normalizeValues(values),
    {
      schema: personsSchema,
      type: 'persons',
      ...config,
    }
  );
}

export function updatePerson({ personId, values, config }) {
  return jsonApiAxios.patch(
    `/persons/${personId}?include=country,countryOfBirth`,
    values,
    {
      schema: personsSchema,
      type: 'persons',
      ...config,
    }
  );
}

export function deletePerson({ personId, config }) {
  return jsonApiAxios.delete(`/persons/${personId}`, config);
}
