import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import AddIcon from '../assets/icons/add-rounded-icon.inline.svg';
import RemoveIcon from '../assets/icons/remove-rounded-icon.inline.svg';
import messages from '../messages/common';

const typeToIcon = {
  add: AddIcon,
  remove: RemoveIcon,
};

const IconButton = ({ type, className, ...props }) => {
  const { formatMessage } = useIntl();
  const alternativeMessage = formatMessage(messages[type]);

  const Icon = typeToIcon[type];

  return (
    <button
      type="button"
      className={clsx(className, {
        'outline-none focus:outline-none opacity-50 cursor-not-allowed pointer-events-none':
          props.disabled,
      })}
      {...props}
      title={alternativeMessage}
    >
      <Icon
        // src={typeToIcon[type]}
        className="h-23 sm:h-25 fill-primary"
        alt={alternativeMessage}
      />
    </button>
  );
};

IconButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['add', 'remove']).isRequired,
};

export default IconButton;
