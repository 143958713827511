import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Components
import FormGroup from './FormGroup';
import RadioButton from './RadioButton';
import InputGroupWrapper from './InputGroup';

const RadioGroupField = props => {
  const {
    children,
    options,
    field,
    hasError,
    row,
    border,
    className,
    form,
    meta,
    callback,
    dataLayerPush,
    extraDescription,
    hasLabel,
    ...rest
  } = props;

  if (!isEmpty(options)) {
    return (
      <>
        {extraDescription && !hasLabel? (
          <div className="relative">
            <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black">
              {extraDescription}
            </p>
          </div>
        ) : hasLabel ? (
         <label className='mb-10 px-15 lg:px-0 font-pt-sans block text-gray-400 font-bold text-lg leading-tight text-gray-800 mb-15 label-description mt-50'>
          {extraDescription}
         </label>
        ): null}
        <FormGroup role="radiogroup" row={row} {...rest}>
          {options?.map((option, index) => (
            <RadioButton
              key={`${field.name}-${option.value}`}
              index={index}
              field={field}
              hasError={hasError}
              row={row}
              border={border}
              className={className}
              form={form}
              meta={meta}
              callback={callback}
              dataLayerPush={dataLayerPush}
              extraDescription={extraDescription}
              {...option}
            />
          ))}
        </FormGroup>
      </>
    );
  }

  return <FormGroup role="radiogroup">{children}</FormGroup>;
};

RadioGroupField.propTypes = {
  border: PropTypes.bool,
  callback: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  dataLayerPush: PropTypes.object,
  extraDescription: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  hasLabel: PropTypes.bool,
  meta: PropTypes.object,
  options: PropTypes.array,
  row: PropTypes.bool,
};

// eslint-disable-next-line
const RadioGroup = props => (
  <InputGroupWrapper {...props} component={RadioGroupField} />
);

RadioGroup.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.node,
  field: PropTypes.object,
  options: PropTypes.array,
};

export default RadioGroup;
