import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';
import PlaneIcon from '../../assets/icons/plane-icon.inline.svg';
import Button from '../../components/Button';
import { useIntl } from 'react-intl';
import searchFlightForm from '../messages/searchFlightForm';
import clsx from 'clsx';
import { formatFlightTime } from '../../utils/formatters';
import { setCustomerFlight } from '../../services/GlobalStore/reducer';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import build from 'redux-object';
import useDataLayer from '../../hooks/useDataLayer';
import { useLocation } from '@reach/router';
const reformatDate = dateString =>
  dateString.replace(/(\d\d\d\d)-(\d\d)-(\d\d)/, '$3/$2/$1');

const findAirlineByCode = (airlines, code) =>
  Object.values(airlines).find(
    airline => airline?.iata === code || airline?.icao === code
  );

const findAirportByCode = (airports, code) =>
  Object.values(airports).find(
    airport => airport?.iata === code || airport?.icao === code
  );

const SingleResult = forwardRef(
  (
    {
      className,
      departureDate,
      flight,
      flightIndex,
      flightsCount,
      flightType,
      submitForm,
      step,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const [state, dispatch] = useGlobalState();
    const location = useLocation();
    const [pushEvent] = useDataLayer();

    useImperativeHandle(ref, () => ({
      selectFlight(event) {
        dispatchFlightSelect(event);
      },
    }));

    const {
      arrival_airport,
      departure_airport,
      flight_number,
      airline_code,
      arrival_time,
      departure_time,
      scheduled_gate_departure_date: flight_date,
    } = flight;

    function dispatchFlightSelect(event) {
      event?.stopPropagation();
      try {
        let airlines = build(state.api, 'airlines');
        let airports = build(state.api, 'airports');
        let airline_id =
          airline_code && findAirlineByCode(airlines, airline_code).id;
        let departure_airport_id =
          departure_airport &&
          findAirportByCode(airports, departure_airport).id;
        let arrival_airport_id =
          arrival_airport && findAirportByCode(airports, arrival_airport).id;
        let fullFlightData = {
          ...flight,
          flight_date: flight_date || departureDate,
          airline_id,
          arrival_airport_id,
          departure_airport_id,
        };
        if (location?.state?.type === 'accountFlights') {
          fullFlightData.type = flightType;
          fullFlightData.claimId = location?.state?.claimId;
          fullFlightData.claim_number = location?.state?.claimNumber;
          dispatch(
            setCustomerFlight(fullFlightData, flightIndex, 'booked')
          ).then(() => {
            submitFunction();
          });
        } else {
          dispatch(
            setCustomerFlight(fullFlightData, flightIndex, flightType)
          ).then(() => {
            submitFunction();
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    const submitFunction = () => {
      if (submitForm) {
        submitForm && submitForm();
      }

      if (typeof flightIndex === 'undefined') {
        pushEvent({ eventLabel: 'direct', eventAction: step });
      } else {
        pushEvent({
          eventLabel: `flight-${flightIndex + 1}`,
          eventAction: step,
        });
      }
    };

    return (
      <li
        className={clsx(className, 'first:mt-0 last:mb-0')}
        onClick={dispatchFlightSelect}
      >
        <div className="relative leading-tight smd:h-20 smd:w-full">
          <div className="w-half inline-block pr-30 text-right">
            <time
              className="departure-time"
              dateTime={formatFlightTime(departure_time)}
            >
              {formatFlightTime(departure_time)}
            </time>
            <span className="airport-code xsd:hidden">{departure_airport}</span>
          </div>
          <PlaneIcon className="plane-icon fill-primary" />
          <div className="w-half inline-block pl-30 arrival-data">
            <time
              dateTime={formatFlightTime(arrival_time)}
              className="arrival-time"
            >
              {formatFlightTime(arrival_time)}
            </time>
            <span className="airport-code xsd:hidden">{arrival_airport}</span>
          </div>
        </div>
        <div className="smd:w-half smd:text-right">
          <div className="flight-data smd:mr-25">
            <span
              className="flight-data-label"
              data-mobile-text={formatMessage(
                searchFlightForm.flightNumberLabelAbbr
              )}
              data-desktop-text={formatMessage(
                searchFlightForm.flightNumberLabelAbbr
              )}
            />
            <span className="">{airline_code + flight_number}</span>
          </div>
        </div>
        <div className="w-half sm:w-auto">
          <div
            className={clsx('flight-data smd:ml-25', {
              highlighted: flight_date && flight_date !== departureDate,
            })}
          >
            <span
              className="flight-data-label"
              data-mobile-text={formatMessage(
                searchFlightForm.flightDateLabelAbbr
              )}
              data-desktop-text={formatMessage(
                searchFlightForm.flightDateLabelAbbr
              )}
            />
            <span>{reformatDate(flight_date || departureDate)}</span>
          </div>
        </div>
        <Button
          theme="primary"
          size="small"
          className="hidden sm:block sm:min-w-100 sm:w-auto"
          onClick={dispatchFlightSelect}
          showIcon={flightsCount === 1}
        >
          {formatMessage(searchFlightForm.selectFlightButton)}
        </Button>
      </li>
    );
  }
);

SingleResult.propTypes = {
  className: PropTypes.string,
  departureDate: PropTypes.string,
  flight: PropTypes.shape({
    airline_code: PropTypes.string,
    scheduled_gate_departure_date: PropTypes.string,
    arrival_airport: PropTypes.string,
    departure_airport: PropTypes.string,
    flight_number: PropTypes.string,
    arrival_time: PropTypes.string,
    departure_time: PropTypes.string,
  }),
  flightIndex: PropTypes.number,
  flightType: PropTypes.oneOf(['booked', 'alternative']),
  flightsCount: PropTypes.number,
  step: PropTypes.string,
  submitForm: PropTypes.func,
};

export default styled(SingleResult)`
  // Mobile
  ${tw`my-20 hover:cursor-pointer sm:my-15 leading-tight shadow-tile hover:shadow-tile-primary rounded-md smd:h-80
  border-gray-200 px-20 py-12 flex flex-wrap items-center justify-center sm:justify-between smd:pb-8`}
  // Desktop
  time {
    ${tw`text-gray-800 text-xl font-bold`}
  }
  .plane-icon {
    ${tw`bg-no-repeat inline-block w-60 h-20 absolute mt-3 -ml-30`};
    background-position: center center;
  }
  .airport-code {
    ${tw`text-base`}
  }
  time + span {
    ${tw`ml-5`}
  }
  time + time {
    ${tw`mx-50`}
  }
  .flight-data-label {
    ${tw`sm:block h-10 sm:h-auto inline text-xs sm:text-xxs mr-5 leading-3`};
  }
  .flight-data {
    ${tw`text-xs rounded-md p-5 inline-block`}
  }
  .highlighted {
    ${tw`bg-red-100 text-red-300`};
    font-weight: 400;
  }
`;
