import { ApiRequestConfig, jsonApiAxios } from '../api';
import { createCustomer } from './customers';
import { createPassenger } from './passengers';
import { createPerson } from './persons';
import { addCustomerFlight } from './customerFlights';
import { getLocalStorageState, PRODUCT_TYPE } from '../appLocalStorage';
import { PassengerModel } from '../../models/PassengerModel';

/**
 * This one is used also in POST calculatorCarts
 * @param record
 * @returns {passenger_count : number}}
 */
export function getDataMeta(record: any) {
  return {
    passenger_count: record.passengers,
    ...(record?.organization && isNaN(record.organization)
      ? {
          organization: record.organization,
        }
      : {}),
  };
}

export const serializeConfig: ApiRequestConfig = {
  type: 'claims',
  schema: {
    attributes: ['product_type', 'organizations', 'cobrandingDossierToken'],
    organizations: {
      ref: 'id',
    },
    cobrandingDossierToken: {
      ref: 'id',
    },
    dataMeta: getDataMeta,
  },
};

function createClaim({
  passengers,
  productType,
  cobrandingDossierTokenId,
}: {
  passengers: number;
  productType: string;
  cobrandingDossierTokenId: any;
}) {
  const cobrandingDossierToken = cobrandingDossierTokenId
    ? {
        cobrandingDossierToken: {
          id: cobrandingDossierTokenId,
        },
      }
    : {};

  const data = {
    product_type: productType,
    passengers,
    ...cobrandingDossierToken,
  };

  return jsonApiAxios.post('/claims', data, {
    ...serializeConfig,
  });
}

interface CustomerFlightParams {
  flight_code: string;
  airline_code: string;
  flight_number: string;
  flight_date: string;
  departure_date: string;
  departure_airport_id: string;
  arrival_airport_id: string;
  airline_id: string;
}

const preNormalizeFlight = (flight: CustomerFlightParams) => {
  const {
    flight_code,
    airline_code,
    flight_number,
    flight_date,
    departure_date,
    departure_airport_id,
    arrival_airport_id,
    airline_id,
  } = flight;

  return {
    flight_code: flight_code || airline_code + flight_number,
    flight_date: flight_date || departure_date,
    departureAirport: { id: departure_airport_id },
    arrivalAirport: { id: arrival_airport_id },
    airline: { id: airline_id },
  };
};

const addFlightsToClaim = async (flights: any, claimId: string) => {
  for (const flight of flights) {
    await addCustomerFlight({
      ...flight,
      claim: { id: claimId },
    });
  }
};

const updatePassengerRelation = ({
  passenger,
  values,
  token,
}: {
  passenger: PassengerModel;
  values: any;
  token: string;
}) => {
  const data: any = {
    schema: {
      attributes: ['booking_number'],
    },
    type: 'passengers',
    ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
  };

  return jsonApiAxios.patch(`/passengers/${passenger.id}`, values, data);
};

export const addBookingNumbers = async ({
  passengers,
  values,
  token,
}: {
  passengers: PassengerModel[];
  values: any;
  token: string;
}) => {
  for (const passenger of passengers) {
    await updatePassengerRelation({ passenger, values, token });
  }
};

const filterOutMainBooker = (mainBookerData: {
  firstname: string;
  lastname: string;
}) => (passengerData: { firstname: string; lastname: string }) =>
  passengerData?.firstname !== mainBookerData?.firstname &&
  passengerData?.lastname !== mainBookerData?.lastname;

/**
 * Creates claim
 * If customer is logged in we omit creating customer
 * If we have signature we sign POA
 * In all cases we return some Promise to resolve with correct redirect
 */
export async function submitClaim({
  signature,
  customerFlights,
  alternativeFlights = [],
  customerIsPassenger,
  cobrandingPassengers = [],
}: {
  signature: any;
  customerFlights: any;
  alternativeFlights: any;
  customerIsPassenger: any;
  cobrandingPassengers: any;
}): Promise<any> {
  const customerAlreadyCreated = !!localStorage.getItem('yo:customerId');
  const cobrandingDossierTokenId = getLocalStorageState(
    'cobrandingDossierTokenId'
  );
  const { email, passengers } = getLocalStorageState('compensationCheck');
  const shouldAuthenticate = getLocalStorageState('shouldAuthenticate');
  const { flightDisruptions = [] } =
    getLocalStorageState('flightDisruptions') || {};
  const productType = getLocalStorageState(PRODUCT_TYPE);
  const personalInfo = getLocalStorageState('personalInfo');

  // First we create customer if user is not logged in
  if (!shouldAuthenticate && !customerAlreadyCreated) {
    const { id: customerId } = await createCustomer({
      ...personalInfo,
      email,
      country: { id: personalInfo.country },
    });
    localStorage.setItem('yo:customerId', customerId);
  }

  // And claim that we will complete in next requests
  const claim = await createClaim({
    passengers,
    productType,
    cobrandingDossierTokenId,
  });

  const { id: claimId }: any = claim;

  localStorage.setItem('yo:claimId', claimId);

  const flightsData = customerFlights.map((flight: any, flightIndex: any) => ({
    ...preNormalizeFlight(flight),
    flight_status: flightDisruptions[flightIndex],
    type: 'booked',
  }));
  const alternativeFlightsData = alternativeFlights.map(
    (alternativeFlight: CustomerFlightParams) => ({
      ...preNormalizeFlight(alternativeFlight),
      type: 'alternative',
      flight_status: null,
    })
  );
  await addFlightsToClaim(flightsData.concat(alternativeFlightsData), claimId);

  const passengersToAdd = customerIsPassenger
    ? cobrandingPassengers?.filter(filterOutMainBooker(personalInfo))
    : cobrandingPassengers;

  /**
   * Adds passengers and ticket_numbers
   *
   * @param passengers
   * @returns {Promise<void>}
   */
  const addPassengers = async (passengersData: any) => {
    for (const passenger of passengersData) {
      const { id: personId } = await createPerson({
        ...passenger,
        signature,
      });

      await createPassenger({
        ticket_number: passenger.ticket_number,
        booking_number: personalInfo.booking_number,
        claim: { id: claimId },
        person: { id: personId },
      });
    }
  };

  if (customerIsPassenger) {
    const { id: personId } = await createPerson({
      ...personalInfo,
      signature,
      email,
      country: { id: personalInfo.country },
      countryOfBirth: { id: personalInfo.country_of_birth },
    });

    const ticketNumber =
      cobrandingPassengers?.length > 0
        ? cobrandingPassengers[0]?.ticket_number
        : null;

    await createPassenger({
      ticket_number: ticketNumber,
      booking_number: personalInfo.booking_number,
      claim: { id: claimId },
      person: { id: personId },
    });
  }

  if (passengersToAdd?.length) {
    await addPassengers(passengersToAdd);
  }

  clearPart1Data();
  // eslint-disable-next-line
  return new Promise(resolve => resolve(claim));
}

export async function getClaimDossierRequirements(claimId: string) {
  return jsonApiAxios.get(`/claims/${claimId}/requirements`);
}

/**
 * Clears all forms from Part I - we must do it when user submits form with
 * email. Might be you changed it on purpose, then you don't want data from
 * previous claim.
 */
export const clearPart1Data = () => {
  localStorage.removeItem('yo:globalState');
  localStorage.removeItem('yo:calculator_type');
  localStorage.removeItem('yo:product_type');
  localStorage.removeItem('yo:connectionType');
  localStorage.removeItem('yo:flight-type-form');
  localStorage.removeItem('yo:flightCompensation-flight-code-search');
  localStorage.removeItem('yo:flightCompensation-by-airports');
  localStorage.removeItem('yo:compensationCheck');
  localStorage.removeItem('yo:personalInfo');
  localStorage.removeItem('yo:claimAmount');
  localStorage.removeItem('yo:placeOfJurisdiction');
  localStorage.removeItem('yo:flightDisruptions');
  localStorage.removeItem('yo:cobrandingDossierTokenId');
};
