import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import isEqual from 'react-fast-compare';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

// Utils
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import usePrevious from '../../hooks/usePrevious';

const FormikPersist = ({ localStorageKey, timeout, favorInitialValues }) => {
  const { values, initialValues, setValues } = useFormikContext();
  const prevValues = usePrevious(values);
  const prevInitialValues = usePrevious(initialValues);
  const [storageValues, setStorageValues] = useLocalStorageState(
    localStorageKey
  );

  // tslint:disable-next-line:no-shadowed-variable
  const saveForm = debounce(values => {
    setStorageValues(values);
  }, timeout);

  useEffect(() => {
    // If enableReinitialize is true the initialValues are being reset to the new values which we want to compare to avoid unnecessary updates
    const formFinishedReinitialising =
      !isEqual(prevInitialValues, initialValues) &&
      isEqual(initialValues, values) &&
      !isEqual(storageValues, values);
    // If the form is done with rendering/reinitialising (enableReinitialize) we want to set the values from localStorage
    if (!isEmpty(storageValues) && formFinishedReinitialising) {
      setValues({
        ...(favorInitialValues
          ? { ...storageValues, ...values }
          : { ...values, ...storageValues }),
      });
    }
    // eslint-disable-next-line
  }, [prevInitialValues, initialValues, values]);

  useEffect(() => {
    // compare previous previous props.values with current form values and save to local storage
    if (
      (!isEqual(prevValues, values) &&
        !isEqual(initialValues, values) &&
        !isEqual(storageValues, values)) ||
      !storageValues
    ) {
      saveForm(values);
    }
    // eslint-disable-next-line
  }, [values, prevValues]);

  return null;
};

FormikPersist.defaultProps = {
  favorInitialValues: false,
  timeout: process.env.NODE_ENV === 'test' ? 1 : 300,
};

FormikPersist.propTypes = {
  debounce: PropTypes.number,
  enableReinitialize: PropTypes.bool,
  favorInitialValues: PropTypes.bool,
  localStorageKey: PropTypes.string.isRequired,
};

export default FormikPersist;
