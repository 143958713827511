import { defineMessages } from 'react-intl';

export default defineMessages({
  disruptionCollapsedLabel: {
    id: 'cc4.form.field.disruption-collapsed-label',
    defaultMessage: 'Disruption: <disruption>{disruption}</disruption>',
  },
  delayed: {
    id: 'cc4.form.field.flight-disruption.delayed',
    defaultMessage: 'Flight delayed',
  },
  canceled: {
    id: 'cc4.form.field.flight-disruption.cancelled',
    defaultMessage: 'Flight cancelled',
  },
  rebooked: {
    id: 'cc4.form.field.flight-disruption.rebooked',
    defaultMessage: 'Flight rebooked',
  },
  diverted: {
    id: 'cc4.form.field.flight-disruption.diverted',
    defaultMessage: 'Flight diverted',
  },
  denied: {
    id: 'cc4.form.field.flight-disruption.denied',
    defaultMessage: 'Denied boarding',
  },
  on_time: {
    id: 'cc4.form.field.flight-disruption.on_time',
    defaultMessage: 'Flight was on time',
  },
  missed_connection: {
    id: 'cc4.form.field.flight-disruption.missed_connection',
    defaultMessage: 'Missed connection',
  },
  placeholder: {
    id: 'cc4.form.field.flight-disruption.placeholder',
    defaultMessage: 'Select an option',
  },
  label: {
    id: 'cc4.form.field.flight-disruption.label',
    defaultMessage: 'What happened?',
  },
});
